import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { Routes } from '../routes';

// pages
import Presentation from './Presentation';
import DashboardOverview from './dashboard/DashboardOverview';
import Transactions from './Transactions';
import TaxHistory from './TaxHistory';
import TransactionReport from '../components/reports/TransactionReport';
import Settings from './Settings';
import BootstrapTables from './tables/BootstrapTables';
import Signin from './examples/Signin';
import Signup from './examples/Signup';
import ForgotPassword from './examples/ForgotPassword';
// import ResetPassword from "./examples/ResetPassword";
import Lock from './examples/Lock';
import NotFoundPage from './examples/NotFound';
import ServerError from './examples/ServerError';
import Filling from './Filling';
import MyTccAccount from './MyTccAccount';
import PayGroup from './Payee';

// documentation pages
import DocsOverview from './documentation/DocsOverview';
import DocsDownload from './documentation/DocsDownload';
import DocsQuickStart from './documentation/DocsQuickStart';
import DocsLicense from './documentation/DocsLicense';
import DocsFolderStructure from './documentation/DocsFolderStructure';
import DocsBuild from './documentation/DocsBuild';
import DocsChangelog from './documentation/DocsChangelog';

// components
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Preloader from '../components/Preloader';

import Accordion from './components/Accordion';
import Alerts from './components/Alerts';
import Badges from './components/Badges';
import Breadcrumbs from './components/Breadcrumbs';
import Buttons from './components/Buttons';
import Forms from './components/Forms';
import Modals from './components/Modals';
import Navs from './components/Navs';
import Navbars from './components/Navbars';
import Pagination from './components/Pagination';
import Popovers from './components/Popovers';
import Progress from './components/Progress';
import Tables from './components/Tables';
import Tabs from './components/Tabs';
import Tooltips from './components/Tooltips';
import Toasts from './components/Toasts';
import KigraSidebar from '../components/KigraSidebar';
import PrintInvoice from './PrintInvoice';
import MdaReportsDashboard from './MdaReportsDashboard';
import MDAReportTransactions from './MDAReportTransactions';
import { useDispatch, useSelector } from 'react-redux';
import { init } from '../redux/action/auth';
import LandPayments from './payments/LandPayments';
import VehiclePayments from './payments/VehiclePayments';
import NonTaxPayment from './payments/NonTaxPayments';
import TaxPayment from './payments/TaxPayments';
import LgaPayments from './payments/LgaPayments';
import CreateTax from './payments/CreateTax';
import UserTransactions from './UserTransactions';
import PaymentInvoice from './PaymentInvoice';
import TaxpayerProfile from './payments/TaxpayerProfile';
import UserSidebar from '../components/UserSidebar';
import TaxMngr from '../pages/tax-mngr/Index';
import NewTaxFormTable from './tax-mngr-table/NewTaxFormTable';
import GenerateReceipt from './generate-receipts';
import PaymentReciptPdf from './PaymentReciptPdf';
import DevTaxManager from './tax-mngr-table/DevTaxManager';
import AdminsMngr from './examples/admins/AdminsMngr';
// import Accordion from "./components/Accordion";
// import Alerts from "./components/Alerts"; 
// import Badges from "./components/Badges";
// import Breadcrumbs from "./components/Breadcrumbs";
// import Buttons from "./components/Buttons";
// import Forms from "./components/Forms";
// import Modals from "./components/Modals";
// import Navs from "./components/Navs";
// import Navbars from "./components/Navbars";
// import Pagination from "./components/Pagination";
// import Popovers from "./components/Popovers";
// import Progress from "./components/Progress";
// import Tables from "./components/Tables";
// import Tabs from "./components/Tabs";
// import Tooltips from "./components/Tooltips";
// import Toasts from "./components/Toasts";
// import KigraSidebar from "../components/KigraSidebar";
// import PrintInvoice from "./PrintInvoice";
// import MdaReportsDashboard from "./MdaReportsDashboard";
// import MDAReportTransactions from "./MDAReportTransactions";
// import { useDispatch, useSelector } from "react-redux";
// import { init } from "../redux/action/auth";
// import LandPayments from "./payments/LandPayments";
// import VehiclePayments from "./payments/VehiclePayments";
// import NonTaxPayment from "./payments/NonTaxPayments";
// import TaxPayment from "./payments/TaxPayments";
// import LgaPayments from "./payments/LgaPayments";
// import CreateTax from "./payments/CreateTax";
// import UserTransactions from "./UserTransactions";
import ViewInvoice from "../components/ViewInvoice";
// import PaymentInvoice from "./PaymentInvoice";
// import TaxpayerProfile from "./payments/TaxpayerProfile";
// import UserSidebar from "../components/UserSidebar";
// import TaxMngr from "../pages/tax-mngr/Index";
// import NewTaxFormTable from "./tax-mngr-table/NewTaxFormTable";
// import GenerateReceipt from "./generate-receipts";
// import PaymentReciptPdf from "./PaymentReciptPdf";
// import DevTaxManager from "./tax-mngr-table/DevTaxManager";
// import AdminsMngr from "./examples/admins/AdminsMngr";

import TaxPayer from './TaxPayer';
import TreeChat from './treeChart/TreeChat';
import ExpressAcct from './ExpressAccount';
import AgentGenerateInvoice from './generate-invoices/AgentGenerateInvoice';
import TaxPayerForm from './examples/TaxPayerForm';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AgentTransactions from './AgentTransactions';
import ReceiptAdmin from './ReceiptAdmin';
import GeneralReports from './GeneralReports';
import RecoverCode from './examples/RecoverCode';
import ReceiptReport from './ReceiptReports';
import ReceiptReportDetails from './ReceiptReportsDetails';
import Profile from './Profile';
// import ResetPassword from "./payments/ResetPassword";
// import { ResetPasswords } from "./payments/ResetPassword";

import CollectionReport from './CollectionReport';
import Accounts from '../components/Accounts';
import PaymentLogs from './PaymentLogs';
import ResetPassword from './examples/ResetPassword';
import { ResetPass } from './ResetApp';
import OnlinePayment from './OnlinePayment';
import MDACollectionReport from './MDASummaryReport';
import NewResetPassword from './examples/NewResetPassword';
import PayInvoice from './generate-receipts/PayInvoice';
import Ahmad1 from './examples/RaiseRequest';
import Ahmad2 from './examples/RequestConfirmation';
import Ahmad3 from './examples/FinalApproval';
import RequestConfirmation from './examples/RequestConfirmation';
import FinalApproval from './examples/FinalApproval';
import RaiseRequest from './examples/RaiseRequest';
import PeriodAdjustment from './examples/PeriodAdjustment';
import Verify from '../components/Verify';
import getVerify from '../components/getVerify';
import Viewverify from '../components/ViewVerify';
import VerifyPayNow from './payments/VerifyPayNow';
// import Dashboard from '../components/OrgDashboard'; //dashboard
import UserDashboard from '../components/UserDashboard'; //dashboard
import TaxType from '../components/taxtype'; //taxtype
import { TaxClearancePDF } from './TaxClearancePDF';
import TaxClearanceIndex from './tax-clearance/TaxClearanceIndex';
import ClearanceApproval from './tax-clearance/ClearanceApproval';
import ClearanceRecommendation from './tax-clearance/ClearanceRecommendation';
import TCCPrinting from './tax-clearance/TCCPrinting';
import TaxClearanceTab from './tax-clearance/TaxClearanceTab';
import TCCDashboard from './tax-clearance/TCCDashboard';
import NominalRollUpload from './assesment/nominal_roll_upload';
import TaxCodeManager from './tax-mngr-table/TaxCodeManager';
import InvoiceReport from './InvoiceReport';
import OrgStaff from './assessment/OrgStaff';
import AdminAssessment from './assessment/AdminAssessment';
import OrgLiabilities from './assessment/OrgLiabilities';
import OrgLedger from './assessment/OrgLedger';
import PendingTax from './assesment/PendingTax';
import Withholding from './assesment/Withholding';
import FilingWHT from './assesment/FilingWHT';
import EmployeeRegistration from './EmployeeRegistration';
import ViewConsultant from '../components/ViewConsultant';
import Apply from '../components/apply/apply';
import Payee from '../components/payee/payee';
import Employee from '../components/Employee/emloyee';

import Organization from './Organization';
import AssessmentTableCard from '../components/taxtype';
import FillingPaye from '../components/FillingPaye';
import { PayeSalaryType } from '../components/PayeSalaryType';
import PayrollUpload from './PayrollUpload';
import PayeDateRange from '../components/PayeDateRange';
import StaffLedger from './assessment/StaffLedger';
import RequestTCC from './assesment/request_tcc';
import UnpaidOrgs from './assessment/UnpaidOrgs';
import NewDashboard from '../components/new_dashboard/NewDashboard';
import AllOrgs from "./assessment/AllOrgs";
import NewTaxPayment from "./payments/components/NewTaxPayment";
import SelectedTax from "../components/selfAssessment";
import PayeSetup from './assesment/PayeSetup';
// import OrgDashboard from '../components/Organization';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const navigateUser = useCallback(() => {
    dispatch(
      init(
        history,
        () => {
          setLoaded(true);
        },
        () => {
          setLoaded(true);
        },
      ),
    );
  }, [dispatch, history]);

  useEffect(() => {
    navigateUser();
  }, [navigateUser]);

  if (!loaded)
    return (
      <>
        <SkeletonTheme baseColor="#f1f1f1" highlightColor={'#ffffff'}>
          <p>
            <Skeleton count={16} height={55} />
          </p>
        </SkeletonTheme>
      </>
    );
  return (
    <>
      {/* {JSON.stringify(!authenticated)} */}
      <Route
        {...rest}
        render={(props) => (
          <>
            <Preloader show={loaded ? false : true} /> <Component {...props} />{' '}
          </>
        )}
      />
    </>
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  const { authenticated } = useSelector((s) => s.auth);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true;
  };

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  };
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(init(history));
  }, [dispatch, history]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <KigraSidebar />

          <main className="content">
            <Navbar />
            {/* <h5>KANO STATE INTERNALLY GENERATED REVENUE ASSURANCE</h5> */}
            {/* <Navbar /> */}
            <div style={{ height: '80vh' }}>
              <Component {...props} />
            </div>
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </>
      )}
    />
  );
};

const RouteUserSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  const { authenticated } = useSelector((s) => s.auth);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true;
  };

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  };
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(init(history));
  }, [dispatch, history]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          {/* User sidebar */}
          <UserSidebar />

          <main className="content">
            <Navbar />
            {/* <h5>KANO STATE INTERNALLY GENERATED REVENUE ASSURANCE</h5> */}
            {/* <Navbar /> */}
            <div style={{ height: '80vh' }}>
              <Component {...props} />
            </div>
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </>
      )}
    />
  );
};

const RouteCustomSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true;
  };

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  };
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(init(history));
  }, [dispatch, history]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <div style={{ height: '90vh' }}>
            <Component {...props} />
          </div>
        </>
      )}
    />
  );
};

export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.Selection.path} component={Presentation} />
    <RouteWithLoader exact path={Routes.OnlinePayment.path} component={OnlinePayment} />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.ResetPass.path} component={ResetPass} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithSidebar exact path={Routes.getVerify.path} component={getVerify} />
    <RouteWithLoader exact path={Routes.Viewverify.path} component={Viewverify} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.RecoverCode.path} component={RecoverCode} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
    {/* pages */}
    <RouteWithSidebar exact path={Routes.TaxPayerTransactions.path} component={UserTransactions} />
    {/**
         * Clearances
         */}
    <RouteUserSidebar exact path={Routes.Filling.path} component={Filling} />
    <RouteUserSidebar exact path={Routes.MyTccAccount.path} component={MyTccAccount} />
    <RouteUserSidebar exact path={Routes.PayGroup.path} component={PayGroup} />
    <RouteUserSidebar exact path={Routes.AssessmentTableCard.path} component={AssessmentTableCard} />
    <RouteUserSidebar exact path={Routes.FillingPaye.path} component={FillingPaye} />
    <RouteUserSidebar exact path={Routes.Organization.path} component={Organization} />
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteCustomSidebar exact path={Routes.TaxPayment.path} component={TaxPayment} />
    <RouteCustomSidebar exact path={Routes.NonTaxPayment.path} component={NonTaxPayment} />
    <RouteWithSidebar exact path={Routes.TaxPayerProfile.path} component={TaxpayerProfile} />
    <RouteUserSidebar exact path={Routes.ResetPassword.path} component={<h2>Hello</h2>} />
    <RouteUserSidebar exact path={Routes.PendingTax.path} component={PendingTax} />
    <RouteUserSidebar exact path={Routes.Withholding.path} component={Withholding} />
    <RouteUserSidebar exact path={Routes.FilingWithholding.path} component={FilingWHT} />
    <RouteUserSidebar exact path={Routes.PayeSalaryType.path} component={PayeSalaryType} />
    <RouteUserSidebar exact path={Routes.EmployeeRegistration.path} component={EmployeeRegistration} />
    <RouteUserSidebar exact path={Routes.UploadPayroll.path} component={PayrollUpload} />
    <RouteUserSidebar exact path={Routes.ViewConsultant.path} component={ViewConsultant} />
    <RouteUserSidebar exact path={Routes.PayeDateRange.path} component={PayeDateRange} />
    <RouteUserSidebar exact path={Routes.ResetPass.path} component={ResetPass} />
    <RouteWithSidebar exact path={Routes.Verify.path} component={Verify} />
    <RouteUserSidebar exact path={Routes.NewResetPassword.path} component={NewResetPassword} />
    {/* <RouteUserSidebar exact path={Routes.UserProfile.path} component={TaxpayerProfile} /> */}
    <RouteUserSidebar exact path={Routes.Profile.path} component={Profile} />
    <RouteCustomSidebar exact path={Routes.VehiclePayments.path} component={VehiclePayments} />
    <RouteCustomSidebar exact path={Routes.LgaPayments.path} component={LgaPayments} />
    <RouteCustomSidebar exact path={Routes.LandPayments.path} component={LandPayments} />
    <RouteUserSidebar exact path={Routes.UserTransactions.path} component={UserTransactions} />
    <RouteWithSidebar exact path={Routes.AdminSetup.path} component={AdminsMngr} />
    {/* <RouteUserSidebar exact path={Routes.Apply.path} component={Apply} /> */}
    <RouteUserSidebar exact path={Routes.Apply.path} component={Apply} />
    <RouteUserSidebar exact path={Routes.Payee.path} component={Payee} />
    <RouteUserSidebar exact path={Routes.Employee.path} component={Employee} />
    <RouteWithSidebar exact path={Routes.CreateTax.path} component={CreateTax} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.MDAReport.path} component={MdaReportsDashboard} />
    <RouteWithSidebar exact path={Routes.ReceiptReport.path} component={ReceiptReport} />
    <RouteWithSidebar exact path={Routes.PaymentLogs.path} component={PaymentLogs} />
    <RouteWithSidebar exact path={Routes.CollectionReport.path} component={CollectionReport} />
    <RouteWithSidebar exact path={Routes.InvoiceReport.path} component={InvoiceReport} />
    <RouteWithSidebar exact path={Routes.AdminResetPassword.path} component={NewResetPassword} />
    <RouteWithSidebar exact path={Routes.RaiseRequest.path} component={RaiseRequest} />
    <RouteWithSidebar exact path={Routes.RequestConfirmation.path} component={RequestConfirmation} />
    <RouteWithSidebar exact path={Routes.FinanlApproval.path} component={FinalApproval} />
    <RouteWithSidebar exact path={Routes.PeriodAdjustment.path} component={PeriodAdjustment} />
    <RouteWithSidebar exact path={Routes.ReceiptReportDetails.path} component={ReceiptReportDetails} />
    <RouteWithSidebar exact path={Routes.MDAReportTransactions.path} component={MDAReportTransactions} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar exact path={Routes.GenerateReceipt.path} component={GenerateReceipt} />
    <RouteWithSidebar exact path={Routes.ExpressAcct.path} component={ExpressAcct} />
    {/* <RouteUserSidebar
  <Switch>
    <RouteWithLoader
      exact
      path={Routes.Selection.path}
      component={Presentation}
    />
    <RouteWithLoader
      exact
      path={Routes.OnlinePayment.path}
      component={OnlinePayment}
    />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.ResetPass.path} component={ResetPass} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithSidebar
      exact
      path={Routes.getVerify.path}
      component={getVerify}
    />
    <RouteWithLoader
      exact
      path={Routes.Viewverify.path}
      component={Viewverify}
    />
    <RouteWithLoader
      exact
      path={Routes.ForgotPassword.path}
      component={ForgotPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.RecoverCode.path}
      component={RecoverCode}
    />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader
      exact
      path={Routes.NotFound.path}
      component={NotFoundPage}
    />
    <RouteWithLoader
      exact
      path={Routes.ServerError.path}
      component={ServerError}
    />
    {/* pages */}
    <RouteWithSidebar
      exact
      path={Routes.TaxPayerTransactions.path}
      component={UserTransactions}
    />
    <RouteUserSidebar
      exact
      path={Routes.ViewInvoice.path}
      component={ViewInvoice}
    />
    {/**
     * Clearances
     */}
    {/* <RouteUserSidebar
      exact
      path={Routes.OrgDashboard.path}
      component={OrgDashboard}
    /> */}
    <RouteUserSidebar exact path={Routes.Filling.path} component={Filling} />
    <RouteUserSidebar exact path={Routes.OrgStaff.path} component={OrgStaff} />

    <RouteUserSidebar
      exact
      path={Routes.MyTccAccount.path}
      component={MyTccAccount}
    />
    <RouteUserSidebar exact path={Routes.PayGroup.path} component={PayGroup} />
    <RouteUserSidebar
      exact
      path={Routes.AssessmentTableCard.path}
      component={AssessmentTableCard}
    />
    <RouteUserSidebar
      exact
      path={Routes.FillingPaye.path}
      component={FillingPaye}
    />
    <RouteUserSidebar
      exact
      path={Routes.Organization.path}
      component={Organization}
    />
    <RouteWithSidebar
      exact
      path={Routes.DashboardOverview.path}
      component={DashboardOverview}
    />
    <RouteCustomSidebar
      exact
      path={Routes.TaxPayment.path}
      component={NewTaxPayment}
    />
    <RouteCustomSidebar
      exact
      path={Routes.NonTaxPayment.path}
      component={NonTaxPayment}
    />
    <RouteWithSidebar
      exact
      path={Routes.TaxPayerProfile.path}
      component={TaxpayerProfile}
    />
    <RouteUserSidebar
      exact
      path={Routes.ResetPassword.path}
      component={<h2>Hello</h2>}
    />
    <RouteUserSidebar
      exact
      path={Routes.selectedTax.path}
      component={SelectedTax}
    />
    <RouteUserSidebar
      exact
      path={Routes.PendingTax.path}
      component={PendingTax}
    />
    <RouteUserSidebar
      exact
      path={Routes.Withholding.path}
      component={Withholding}
    />
    <RouteUserSidebar
      exact
      path={Routes.FilingWithholding.path}
      component={FilingWHT}
    />
    <RouteUserSidebar
      exact
      path={Routes.PayeSalaryType.path}
      component={PayeSalaryType}
    />
    <RouteUserSidebar
      exact
      path={Routes.EmployeeRegistration.path}
      component={EmployeeRegistration}
    />
    <RouteUserSidebar
      exact
      path={Routes.UploadPayroll.path}
      component={PayrollUpload}
    />
    <RouteUserSidebar
      exact
      path={Routes.ViewConsultant.path}
      component={ViewConsultant}
    />
    <RouteUserSidebar
      exact
      path={Routes.PayeDateRange.path}
      component={PayeDateRange}
    />
    <RouteUserSidebar
      exact
      path={Routes.ResetPass.path}
      component={ResetPass}
    />
    <RouteWithSidebar exact path={Routes.Verify.path} component={Verify} />
    <RouteUserSidebar
      exact
      path={Routes.NewResetPassword.path}
      component={NewResetPassword}
    />
    {/* <RouteUserSidebar
      exact
      path={Routes.UserProfile.path}
      component={TaxpayerProfile}
    /> */}
    <RouteUserSidebar exact path={Routes.Profile.path} component={Profile} />
    <RouteCustomSidebar
      exact
      path={Routes.VehiclePayments.path}
      component={VehiclePayments}
    />
    <RouteCustomSidebar
      exact
      path={Routes.LgaPayments.path}
      component={LgaPayments}
    />
    <RouteCustomSidebar
      exact
      path={Routes.LandPayments.path}
      component={LandPayments}
    />
    <RouteUserSidebar
      exact
      path={Routes.UserTransactions.path}
      component={UserTransactions}
    />
    <RouteWithSidebar
      exact
      path={Routes.AdminSetup.path}
      component={AdminsMngr}
    />
    {/* <RouteUserSidebar exact path={Routes.Apply.path} component={Apply} /> */}
    <RouteUserSidebar exact path={Routes.Apply.path} component={Apply} />
    <RouteUserSidebar exact path={Routes.Payee.path} component={Payee} />
    <RouteUserSidebar exact path={Routes.Employee.path} component={Employee} />
    <RouteWithSidebar
      exact
      path={Routes.CreateTax.path}
      component={CreateTax}
    />
    <RouteWithSidebar
      exact
      path={Routes.Transactions.path}
      component={Transactions}
    />
    <RouteWithSidebar
      exact
      path={Routes.MDAReport.path}
      component={MdaReportsDashboard}
    />
    <RouteWithSidebar
      exact
      path={Routes.ReceiptReport.path}
      component={ReceiptReport}
    />
    <RouteWithSidebar
      exact
      path={Routes.PaymentLogs.path}
      component={PaymentLogs}
    />
    <RouteWithSidebar
      exact
      path={Routes.CollectionReport.path}
      component={CollectionReport}
    />
    <RouteWithSidebar
      exact
      path={Routes.InvoiceReport.path}
      component={InvoiceReport}
    />
    <RouteWithSidebar
      exact
      path={Routes.AdminResetPassword.path}
      component={NewResetPassword}
    />
    <RouteWithSidebar
      exact
      path={Routes.RaiseRequest.path}
      component={RaiseRequest}
    />
    <RouteWithSidebar
      exact
      path={Routes.RequestConfirmation.path}
      component={RequestConfirmation}
    />
    <RouteWithSidebar
      exact
      path={Routes.FinanlApproval.path}
      component={FinalApproval}
    />
    <RouteWithSidebar
      exact
      path={Routes.PeriodAdjustment.path}
      component={PeriodAdjustment}
    />
    <RouteWithSidebar
      exact
      path={Routes.ReceiptReportDetails.path}
      component={ReceiptReportDetails}
    />
    <RouteWithSidebar
      exact
      path={Routes.MDAReportTransactions.path}
      component={MDAReportTransactions}
    />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar
      exact
      path={Routes.GenerateReceipt.path}
      component={GenerateReceipt}
    />
    <RouteWithSidebar
      exact
      path={Routes.ExpressAcct.path}
      component={ExpressAcct}
    />
    {/* <RouteUserSidebar
      exact
      path={Routes.ExpressAcct2.path}
      component={ExpressAcct}
    /> */}
    <RouteUserSidebar exact path={Routes.ExpressAcct2.path} component={Accounts} />
    <RouteCustomSidebar exact path={Routes.AgentTransactions.path} component={AgentTransactions} />
    {/* <RouteWithSidebar
  <Switch>
    <RouteWithLoader
      exact
      path={Routes.Selection.path}
      component={Presentation}
    />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithSidebar
      exact
      path={Routes.CreateTaxPayer.path}
      component={TaxPayerForm}
    />
    <RouteWithLoader
      exact
      path={Routes.ForgotPassword.path}
      component={ForgotPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.ResetPassword.path}
      component={ResetPassword}
    />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader
      exact
      path={Routes.NotFound.path}
      component={NotFoundPage}
    />
    <RouteWithLoader
      exact
      path={Routes.ServerError.path}
      component={ServerError}
    />
     <RouteWithSidebar
      exact
      path={Routes.GenerateReceipt.path}
      component={GenerateReceipt}
    />
    {/* agent link */}
    {/* <RouteWithSidebar
      exact
      path={Routes.AgentGenerateInvoice.path}
      component={AgentGenerateInvoice}
    /> */}
    {/* pages */}
    <RouteWithSidebar exact path={Routes.TaxPayerTransactions.path} component={UserTransactions} />
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteCustomSidebar exact path={Routes.TaxPayment.path} component={TaxPayment} />
    <RouteCustomSidebar exact path={Routes.NonTaxPayment.path} component={NonTaxPayment} />
    <RouteWithSidebar exact path={Routes.TaxPayerProfile.path} component={TaxpayerProfile} />
    <RouteUserSidebar exact path={Routes.UserTransactions.path} component={UserTransactions} />
    <RouteUserSidebar exact path={Routes.UserProfile.path} component={TaxpayerProfile} />
    <RouteCustomSidebar exact path={Routes.VehiclePayments.path} component={VehiclePayments} />
    <RouteCustomSidebar exact path={Routes.LgaPayments.path} component={LgaPayments} />
    <RouteCustomSidebar exact path={Routes.LandPayments.path} component={LandPayments} />
    <RouteUserSidebar exact path={Routes.UserTransactions.path} component={UserTransactions} />
    <RouteWithSidebar exact path={Routes.AdminSetup.path} component={AdminsMngr} />
    <RouteWithSidebar exact path={Routes.CreateTax.path} component={CreateTax} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.MDAReport.path} component={MdaReportsDashboard} />
    <RouteWithSidebar exact path={Routes.MDAReportTransactions.path} component={MDAReportTransactions} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar exact path={Routes.GenerateReceipt.path} component={GenerateReceipt} />
    <RouteWithSidebar exact path={Routes.Departments.path} component={GenerateReceipt} />
    {/* agent link */}
    <RouteWithSidebar exact path={Routes.AgentGenerateInvoice.path} component={AgentGenerateInvoice} />
    {/* <RouteWithSidebar
      exact
      path={`${Routes.GenerateReceipt.path}/:ref_no`}
      component={GenerateReceipt}
    /> */}
    <RouteUserSidebar exact path={Routes.NominalRollUpload.path} component={NominalRollUpload} />
    <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />
    <RouteWithSidebar
      exact
      path={Routes.TaxMngrTable.path}
      component={(props) => <NewTaxFormTable {...props} type="TAX" />}
    />
    <RouteWithSidebar
      exact
      path={Routes.NonTaxSetting.path}
      component={(props) => <NewTaxFormTable {...props} type="NON TAX" />}
    />
    <RouteWithSidebar
      exact
      path={Routes.VehicleTaxSetting.path}
      component={(props) => <NewTaxFormTable {...props} type="VEHICLES" />}
    />
    <RouteWithSidebar
      exact
      path={Routes.LandTaxSetting.path}
      component={(props) => <NewTaxFormTable {...props} type="LAND" />}
    />
    <RouteWithSidebar
      exact
      path={Routes.LGATaxSetting.path}
      component={(props) => <NewTaxFormTable {...props} type="LGA" />}
    />
    <RouteWithSidebar exact path={Routes.Tree.path} component={TreeChat} />
    {/* Dont touch the following route is for Developer Admin: */}
    <RouteWithSidebar exact path={Routes.AdminsMngr.path} component={AdminsMngr} />
    {/* Dont touch the following route is for Developer Admin: */}
    <RouteWithSidebar exact path={Routes.TaxManager.path} component={DevTaxManager} />
    <RouteWithSidebar exact path={Routes.TaxCodeManager.path} component={TaxCodeManager} />
    {/* components */}
    <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
    <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
    <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />
    {/* documentation */}
    <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
    <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
    <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
    <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
    <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
    <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
    <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />
    <RouteWithSidebar exact path={Routes.ReportTransaction.path} component={TransactionReport} />
    <RouteWithSidebar exact path={Routes.viewTax.path} component={TaxHistory} />
    <RouteWithSidebar exact path={Routes.printInvoice.path} component={PrintInvoice} />{' '}
    <RouteWithSidebar exact path={Routes.TaxMngr.path} component={TaxMngr} />
    <RouteWithSidebar exact path={Routes.adminPaymentInvoice.path} component={PaymentInvoice} />
    <RouteWithSidebar exact path={Routes.TaxClearance.path} component={TaxClearanceIndex} />
    <RouteWithSidebar exact path={Routes.TaxClearanceTab.path} component={TaxClearanceTab} />
    {/* <RouteWithSidebar
      exact
      path={Routes.NominalRollUpload.path}
      component={NominalRollUpload}
    /> */}
    <RouteWithSidebar exact path={Routes.TCCRecommendation.path} component={ClearanceRecommendation} />
    <RouteWithSidebar exact path={Routes.TCCDashboard.path} component={TCCDashboard} />
    <RouteWithSidebar exact path={Routes.TCCPrinting.path} component={TCCPrinting} />
    <RouteWithSidebar exact path={Routes.ClearanceApproval.path} component={ClearanceApproval} />
    <RouteUserSidebar exact path={Routes.paymentInvoice.path} component={PaymentInvoice} />
    <RouteUserSidebar exact path={Routes.RequestTCC.path} component={RequestTCC} />
    <RouteWithSidebar exact path={Routes.adminPaymentReceipt.path} component={ReceiptAdmin} />{' '}
    <RouteWithSidebar exact path={Routes.PayInvoice.path} component={PayInvoice} />

    <RouteWithSidebar
      exact
      path={Routes.TCCRecommendation.path}
      component={ClearanceRecommendation}
    />
    <RouteWithSidebar
      exact
      path={Routes.TCCDashboard.path}
      component={TCCDashboard}
    />
    <RouteWithSidebar
      exact
      path={Routes.TCCPrinting.path}
      component={TCCPrinting}
    />
    <RouteWithSidebar
      exact
      path={Routes.ClearanceApproval.path}
      component={ClearanceApproval}
    />
    <RouteUserSidebar
      exact
      path={Routes.paymentInvoice.path}
      component={PaymentInvoice}
    />
    <RouteUserSidebar
      exact
      path={Routes.RequestTCC.path}
      component={RequestTCC}
    />
    <RouteWithSidebar
      exact
      path={Routes.adminPaymentReceipt.path}
      component={ReceiptAdmin}
    />{" "}
    <RouteWithSidebar
      exact
      path={Routes.PayInvoice.path}
      component={PayInvoice}
    />
    {/* <RouteUserSidebar //bdfvndkvndfkvndfkvndfkvndfkvnfkvndkfnvdnvndvnkfnvdknkdnvkdfnvdkvndfkvnd */}
    <RouteUserSidebar exact path={Routes.OrgDashboard.path} component={Organization} />
    <RouteUserSidebar exact path={Routes.UserDashboard.path} component={UserDashboard} />

    <RouteUserSidebar // TAX TABLE
      exact
      path={Routes.TaxType.path}
      component={TaxType}
    />
    <RouteWithSidebar exact path={Routes.VerifyPayNow1.path} component={VerifyPayNow} />
    <RouteUserSidebar exact path={Routes.paymentReceipt.path} component={PaymentReciptPdf} />
    <RouteUserSidebar exact path={Routes.PayInvoice1.path} component={PayInvoice} />
    <RouteUserSidebar exact path={Routes.VerifyPayNow.path} component={VerifyPayNow} />
    <RouteWithSidebar exact path={Routes.TaxPayers.path} component={TaxPayer} />
    <RouteWithSidebar exact path={Routes.adminPaymentReceipt.path} component={PaymentReciptPdf} />
    <RouteWithSidebar
      exact
      path={Routes.GenerateReports.path}
      component={(props) => <GeneralReports {...props} general={true} />}
    />
    <RouteWithSidebar
      exact
      path={Routes.SummaryReports.path}
      component={(props) => <MDACollectionReport {...props} general={true} />}
    />
    <RouteWithSidebar
      exact
      path={Routes.BankReport.path}
      component={(props) => <GeneralReports {...props} view_by="Banks" />}
    />
    <RouteWithSidebar
      exact
      path={Routes.TaxReport.path}
      component={(props) => <GeneralReports {...props} sector="TAX" />}
    />
    <RouteWithSidebar
      exact
      path={Routes.NonTaxReport.path}
      component={(props) => <GeneralReports {...props} sector="NON TAX" />}
    />
    <RouteWithSidebar
      exact
      path={Routes.LandReport.path}
      component={(props) => <GeneralReports {...props} sector="LAND" />}
    />
    <RouteWithSidebar
      exact
      path={Routes.LGAReport.path}
      component={(props) => <GeneralReports {...props} sector="LGA" />}
    />
    <RouteWithSidebar
      exact
      path={Routes.VehicleReport.path}
      component={(props) => <GeneralReports {...props} sector="VEHICLE" />}
    />
    <RouteWithSidebar exact path={Routes.AdminAssessment.path} component={AdminAssessment} />
    <RouteWithSidebar exact path={Routes.OrgLiabilities.path} component={OrgLiabilities} />
    <RouteWithSidebar exact path={Routes.OrgLedger.path} component={OrgLedger} />
    <RouteUserSidebar exact path={Routes.PayeSetup.path} component={PayeSetup} />

    <RouteWithSidebar exact path={Routes.StaffLedger.path} component={StaffLedger} />
    <RouteUserSidebar exact path={Routes.StaffLedgerUser.path} component={StaffLedger} />

    <RouteWithSidebar exact path={Routes.UnpaidOrgs.path} component={UnpaidOrgs} />
    <RouteWithSidebar exact path={Routes.NewDashboard.path} component={NewDashboard} />
    <RouteWithSidebar
      exact
      path={Routes.AdminAssessment.path}
      component={AdminAssessment}
    />
    <RouteWithSidebar
      exact
      path={Routes.OrgLiabilities.path}
      component={OrgLiabilities}
    />
    <RouteWithSidebar
      exact
      path={Routes.OrgLedger.path}
      component={OrgLedger}
    />
    <RouteWithSidebar
      exact
      path={Routes.StaffLedger.path}
      component={StaffLedger}
    />
    <RouteWithSidebar
      exact
      path={Routes.UnpaidOrgs.path}
      component={UnpaidOrgs}
    />
    <RouteWithSidebar
      exact
      path={Routes.NewDashboard.path}
      component={NewDashboard}
    />
    <RouteWithSidebar exact path={Routes.AllOrgs.path} component={AllOrgs} />
    <Redirect to={Routes.NotFound.path} />
  </Switch >
);
