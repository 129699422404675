import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input,
  Table,
  Row,
  Col,
} from "reactstrap";
import moment from "moment";
import { _fetchApi, _postApi, apiURL, calculateTax } from "../redux/action/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
// import    from "moment/moment";
import { toCurrency } from "../utils";
import { FaFilePdf } from "react-icons/fa";
import { Modal } from "@themesberg/react-bootstrap";
import CustomTypeahead from "../components/UI/CustomTypeahead";
import { CustomButton } from "../components/UI";
import PayAssessment from "./generate-receipts/PayAssessment";

export default function Filling() {
  const { tax_account, user } = useSelector(s => s.auth)
  const [form, setForm] = useState({
    assessment_name: "",
    rev_code: '',
    assessment_type: "",
    profession: "",
    director_fee: "",
    pension: tax_account.pension || "",
    rent: "",
    interest: "",
    dividend: "",
    name: tax_account.name,
    phone_number: tax_account.phone,
    nationality: "",
    residence: "",
    occupation: tax_account.occupation,
    commencement_date: "",
    no_of_domestic_staff: "",
    bankers: "",
    childrens_school: "",
    name_of_hospital: "",
    no_of_farmland: "",
    no_of_properties: "",
    no_of_apperentices: "",
    no_of_vehicles: "",
    file_url: "",
    query_type: "insert",
    taxID: tax_account.taxID,
    created_by: user.name,
  });
  // const [value, setValue] = useState([]);
  const TaxType = [
    {
      title: "Direct Assessment on High Networth",
      economic_code: 12010501,
    },
    {
      title: "Direct Assessment on Mix-Income",
      economic_code: 12010502,
    },
    {
      title: "Direct Assessment on Expatriates",
      economic_code: 12010503,
    },
    {
      title: "Direct Assessment on Presumptive/Medium",
      economic_code: 12010400,
    },
  ];
  const [step, setStep] = useState(1);
  const [taxType, setTaxType] = useState(TaxType);
  const [showPDF, setShowPDF] = useState(false)
  useEffect(() => {
    if (tax_account.taxID)
      _fetchApi(
        `/kigra-taxes?query_type=get-da-heads`,
        (resp) => {
          // alert(JSON.stringify(resp))
          if (resp.success) {
            setTaxType(resp.result);
            setForm((p) => ({
              ...p,
              name: tax_account.name,
              taxID: tax_account.taxID,
              phone_number: tax_account.phone,
              occupation: tax_account.occupation,
              nationality: tax_account.nationality
            }))
          }
        },
      );
  }, [tax_account.taxID]);

  const currentYear = moment().year();
  const period = Array.from({ length: 3 }, (_, i) => currentYear - (i + 1));
  // const [taxTypeValue, setTaxTypeValue] = useState([]);
  const [cursorPositions, setCursorPositions] = useState({});

  // Function to format number with commas
  const formatNumberWithCommas = (number) => {
    if (!number) return number;
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };
  const handleCurrencyChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/[^0-9.]/g, "");

    setForm((prevForm) => ({
      ...prevForm,
      [name]: numericValue,
    }));

    const cursorPos = e.target.selectionStart;
    setCursorPositions((prevPositions) => ({
      ...prevPositions,
      [name]: cursorPos,
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const formattedValue = formatNumberWithCommas(value);
    setForm((prevForm) => ({
      ...prevForm,
      [name]: formattedValue,
    }));
  };

  const handleFocus = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/,/g, "");
    setForm((prevForm) => ({
      ...prevForm,
      [name]: numericValue,
    }));
  };


  const setCursorPosition = useCallback((inputName) => {
    const input = document.getElementsByName(inputName)[0];
    if (cursorPositions[inputName] !== undefined && input) {
      input.setSelectionRange(cursorPositions[inputName], cursorPositions[inputName]);
    }
  }, [cursorPositions]);

  useEffect(() => {
    Object.keys(cursorPositions).forEach((inputName) => {
      setCursorPosition(inputName);
    });
  }, [form, setCursorPosition]);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({
      ...p,
      [name]: value,
    }))
  }
  const handleChangeTaxType = (selected) => {
    if (selected.length) {
      setForm((p) => ({
        ...p,
        ...selected[0],
        assessment_name: selected[0].title,
        rev_code: selected[0].economic_code,
        economic_code: selected[0].economic_code,
        item_code: selected[0].id,
      }))
    }
  };
  const handleChangePeriod = (selected) => {
    setForm((p) => ({
      ...p, year: selected,
      start_date: moment(`${selected}-01-01`).format('YYYY-MM-DD'),
      end_date: moment(`${selected}-12-30`).format('YYYY-MM-DD')
    })
    );
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  // console.log(`taxType: ${taxTypeValue}, period: ${periodValue}`);
  const [selectedFile, setSelectedFile] = useState(null);

  const [directAssessment, setDirectAssessment] = useState({});
  const [directAssessments, setDirectAssessments] = useState([]);
  const [refNo, setRefNo] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [loading1, setLoading1] = useState(false);

  const handleUploadFile = async ({ target: { files } }) => {
    const file = files[0];
    setSelectedFile(file);
    if (!selectedFile) {
      toast.error('Please select a file first.');
      return;
    }
  };

  const uploadFile = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await fetch(`${apiURL}/assessments/file-upload`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          if (data.file_url) {
            toast.success('File uploaded successfully!');
            setForm((prev) => ({ ...prev, file_url: data.file_url }));
          } else {
            toast.error('File upload failed: Missing file_url in response.');
          }
        } else {
          toast.error('File upload failed.');
        }
      } catch (error) {
        toast.error('An error occurred while uploading the file.');
      }
    } else {
      toast.error('Please choose the file first.');
    }
  }
  function sumNumbers(nums = [0, 0, 0, 0, 0]) {
    // Ensure we only sum the first five numbers and parse them as floats
    let sum = nums.reduce((acc, num) => {
      return acc + parseFloat(num);
    }, 0);

    // Return the sum formatted to two decimal places
    return sum.toFixed(2);
  }

  const profession = parseFloat(`${form.profession}`.replace(/[^0-9.]/g, "")) || 0;
  const directorsFee = parseFloat(`${form.director_fee}`.replace(/[^0-9.]/g, "")) || 0;
  const pension = parseFloat(`${form.pension}`.replace(/[^0-9.]/g, "")) || 0;
  const rent = parseFloat(form.rent.replace(/[^0-9.]/g, "")) || 0;
  const interest = parseFloat(`${form.interest}`.replace(/[^0-9.]/g, "")) || 0;
  const dividend = parseFloat(`${form.dividend}`.replace(/[^0-9.]/g, "")) || 0;

  const total = sumNumbers([profession, directorsFee, rent, interest, dividend]);

  // Assuming calculateTax() is defined elsewhere and properly handles its input
  const cal_tax = calculateTax(total - pension);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    _postApi('/assessments/direct-assessment',
      { ...form, amount: cal_tax, profession, directorsFee, pension, rent, interest, dividend },
      (res) => {
        setLoading(false);
        if (res.success) {
          setDirectAssessment(res.data[0]);
          toast.success('Submitted');
          setRefNo(res?.transaction?.ref_no);
        }
      },
      (err) => {
        setLoading(false);
        console.error(err);
        toast.error('Not working');
      }
    );
  };
  const loadAssessments = useCallback(() => {
    if (tax_account.taxID && !directAssessments.length) {
      _postApi('/assessments/direct-assessment',
        { taxID: tax_account.taxID, query_type: 'tax-payer-history' },
        (res) => {
          if (res.success) {
            setDirectAssessments(res.data);
          }
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }, [tax_account.taxID, directAssessments.length]);

  useEffect(() => {
    loadAssessments();
  }, [loadAssessments]);

  const historyYears = directAssessments.length > 0 ? directAssessments.map(x => `${x.year}`) : [];

  const filteredYears = historyYears.length ? period.filter(year => !historyYears?.includes(`${year}`)) : period;


  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Card>
            {/* {JSON.stringify({ historyYears, filteredYears, directAssessments: directAssessments.map(entry => entry.year) })} */}
            <CardTitle tag="h5" className="p-3 bg-light">
              Direct Assessment
            </CardTitle>
            <CardBody>
              <Row>
                <Col md={12}>
                  <Label>
                    Tax Type<span className="text-danger">*</span>
                  </Label>
                  <CustomTypeahead
                    id="taxTypeInput"
                    placeholder="Select Assessment Type"
                    options={taxType}
                    onInputChange={(e) => {
                      setForm((p) => ({
                        ...p,
                        assessment_name: '',
                        rev_code: '',
                        economic_code: '',
                      }))
                    }}
                    onChange={handleChangeTaxType}
                    clearButton
                    labelKey="title"
                    col={12}
                  />
                </Col>
                <Col md={12} className='mt-2'>
                  <Label>
                    Period<span className="text-danger">*</span>
                  </Label>
                  <CustomTypeahead
                    id="periodInput"
                    name="year"
                    placeholder="Income Declaration Year"
                    options={filteredYears.map(x => `${x}`)}
                    onChange={handleChangePeriod}
                    value={form.year}
                    col={12}
                    clearButton
                  />
                </Col>
              </Row>
            </CardBody>
          </Card >
        );
      case 2:
        return (
          <Card>
            <CardTitle tag="h5" className="bg-light p-3 mb-1">
              {" "}
              Income Declaretion Year: {form.year}
            </CardTitle>
            <CardBody className="p-2">
              <div className="w-40">
                <Label>
                  <input
                    type="radio"
                    value="non-liable"
                    name="assessment_type"
                    className="mx-2"
                  />
                  Non-Liable
                </Label>

                <Label>
                  <input
                    type="radio"
                    value="exempted"
                    name="assessment_type"
                    className="mx-2"
                  />
                  Exempted
                </Label>
              </div>

              <Table bordered>
                <thead>
                  <tr>
                    <th>Source of Income</th>
                    <th>Income</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Input
                        type="text"
                        value="Trade/Business/Profession"
                        disabled
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <Input
                        type="text"
                        placeholder="100"
                        value={form.profession}
                        name="profession"
                        onChange={handleCurrencyChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Input type="text" value="Directors Fee" disabled />
                    </td>
                    <td>
                      <Input
                        type="text"
                        placeholder="100"
                        value={form.director_fee}
                        name="director_fee"
                        onChange={handleCurrencyChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Input type="text" value="Pension" disabled />
                    </td>
                    <td>
                      <Input
                        type="text"
                        placeholder="100"
                        value={form.pension}
                        name="pension"
                        onChange={handleCurrencyChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Input type="text" value="Rent" disabled />
                    </td>
                    <td>
                      <Input
                        type="text"
                        placeholder="100"
                        value={form.rent}
                        name="rent"
                        onChange={handleCurrencyChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Input type="text" value="Intrest" disabled />
                    </td>
                    <td>
                      <Input
                        type="text"
                        placeholder="100"
                        value={form.interest}
                        name="interest"
                        onChange={handleCurrencyChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Input type="text" value="Dividend" disabled />
                    </td>
                    <td>
                      <Input
                        type="text"
                        placeholder="100"
                        value={form.dividend}
                        name="dividend"
                        onChange={handleCurrencyChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        );
      case 3:
        return (
          <Card>
            <CardTitle tag="h5" className="bg-light p-3 mb-1">
              {" "}
              {form.assessment_name}: {form.year}
            </CardTitle>
            <CardBody>
              <Row className="py-2">
                <Col md={3}>Name</Col>
                <Col md={9}>
                  <Input
                    type="text"
                    value={form.name}
                    name="name"
                    placeholder="Your Name"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Phone Number</Col>
                <Col md={9}>
                  <Input
                    type="text"
                    value={form.phone_number}
                    placeholder="Your Phone Number"
                    name="phone_number"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Nationality</Col>
                <Col md={9}>
                  <Input
                    type="text"
                    value={form.nationality}
                    placeholder="Nationality"
                    name="nationality"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Residence</Col>
                <Col md={9} className="d-flex flex-column">
                  <div>
                    <Label>
                      <Input
                        type="radio"
                        value="personal property"
                        name="residence_type"
                        onChange={handleChange}
                        className="mx-2"
                      />
                      Personal Property
                    </Label>
                  </div>
                  <div>
                    <Label>
                      <Input
                        type="radio"
                        value="rented property"
                        name="residence_type"
                        onChange={handleChange}
                        className="mx-2"
                      />
                      Rented Property
                    </Label>
                  </div>
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Type of Business/Trade</Col>
                <Col md={9}>
                  <Input
                    type="text"
                    value={form.occupation}
                    placeholder=" Business/Trade Type"
                    name="occupation"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Commencement Period</Col>
                <Col md={9}>
                  <Input
                    type="date"
                    value={form.commencement_date}
                    placeholder="Commencement Period"
                    name="commencement_date"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Number of Domestic staff</Col>
                <Col md={9}>
                  <Input
                    type="number"
                    value={form.no_of_domestic_staff}
                    placeholder="Total Number of domestic Staff"
                    name="no_of_domestic_staff"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Treasury Bank</Col>
                <Col md={9}>
                  <Input
                    type="text"
                    value={form.bankers}
                    name="bankers"
                    placeholder="Name of the Bank"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Name of Children's School</Col>
                <Col md={9}>
                  <Input
                    type="text"
                    value={form.childrens_school}
                    placeholder="Name of Children's School"
                    name="childrens_school"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Name of hostipal</Col>
                <Col md={9}>
                  <Input
                    type="text"
                    value={form.title_of_hospital}
                    placeholder="Name of Hospital"
                    name="name_of_hospital"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Number of farmlands</Col>
                <Col md={9}>
                  <Input
                    type="number"
                    value={form.no_of_farmland}
                    placeholder="Total number of farmland"
                    name="no_of_farmland"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Number of Properties</Col>
                <Col md={9}>
                  <Input
                    type="number"
                    value={form.no_of_properties}
                    placeholder="Total Numbar of Properties"
                    name="no_of_properties"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Number of Apperentices</Col>
                <Col md={9}>
                  <Input
                    type="number"
                    value={form.no_of_apperentices}
                    placeholder="Total Number of Apperentices"
                    name="no_of_apperentices"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={3}>Number of Vehicle</Col>
                <Col md={9}>
                  <Input
                    type="number"
                    value={form.no_of_vehicles}
                    placeholder="Total Number of Vehicles"
                    name="no_of_vehicles"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <div className="bg-light text-center p-3">
                <p className="w-85">
                  I hereby declare that the information given in part A and B
                  of this form and the supporting document herewith attached
                  contain the true and correct statement of income in respect
                  of all taxes accrued in, brought in, received or derived in
                  Nigeria; that I am liable to be prosecuted for rendering any
                  false statement and returns which contravenes the Section
                  41(1)(2) and (3) of Personal Income Tax Act and the Kano
                  State Tax (Codification and Consolidation) Law No. 8 of
                  2021, Section 26(1)(a) (b), (c) and (d).{" "}
                </p>
              </div>
            </CardBody>
          </Card>
        );
      case 4:
        return (
          <Card>
            {/* {JSON.stringify(form)} */}
            <CardTitle tag="h5" className="bg-light p-3 mb-1">
              {" "}
              Files Upload
            </CardTitle>
            <CardBody>
              <Label>Upload Document</Label>
              <div>
                <Input
                  type="file"
                  // value={selectedFile}
                  name="file"
                  onChange={handleUploadFile}
                />
              </div>
              <Label>Upload your supporting tax document</Label>
              <ul>
                <li>Income declaration Statement</li>
                <li>Financial Statement</li>
                <li>Bank Statenment</li>
              </ul>
              <Label>Note:</Label>
              <ul>
                <li>
                  Upload should be in image (.jpeg, .jpg, .png) or PDF
                  formats.
                </li>
                <li>Each uploaded should not exceed 5MB</li>
                {form.file_url && <li> <a href={apiURL + '/' + form.file_url}>Preview</a></li>}
              </ul>
              <Button onClick={uploadFile} className="bg-success p-3 "> Save Documents</Button>
            </CardBody>
          </Card>
        );
      case 5:
        return (
          <Card>
            <CardTitle tag="h5" className="bg-light p-3 mb-1">
              {" "}
              Assessment Summary
            </CardTitle>
            <CardBody>
              <Table bordered>
                <thead>
                  <tr className="table-success">
                    <th>Source of Income</th>
                    <th>Gross Income</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Trade/Business/Profession</td>
                    <td>{formatNumberWithCommas(form.profession)}</td>
                  </tr>
                  <tr>
                    <td>Directors Fee</td>
                    <td>{formatNumberWithCommas(form.director_fee)}</td>
                  </tr>
                  <tr>
                    <td>Pension</td>
                    <td>{formatNumberWithCommas(form.pension)}</td>
                  </tr>
                  <tr>
                    <td>Rent</td>
                    <td>{formatNumberWithCommas(form.rent)}</td>
                  </tr>
                  <tr>
                    <td>Interrest</td>
                    <td>{formatNumberWithCommas(form.interest)}</td>
                  </tr>
                  <tr>
                    <td>Dividend</td>
                    <td>{formatNumberWithCommas(form.dividend)}</td>
                  </tr>
                  <tr className="table-success">
                    <td>Total Gross Income</td>
                    <td>{toCurrency(total)}</td>
                  </tr>
                  <tr className="bg-success">
                    <td>Withholding Credit Balance</td>
                    <td>
                      {toCurrency(cal_tax)}</td>
                  </tr>
                </tbody>
              </Table>
              <div className="bg-light py-5 px-4">
                <p>
                  I delcare that this return contains a true and correct
                  statement of my personal income computed in accordance with
                  the provisions of the personal income tax Law No. 105 of
                  1993, (as amended), and of any regulations made thereunder,
                  and that the particulars given are true and complete. Also,
                  i declare that the deduction claimed bt me have brrn
                  computed as aforesaid, and are accurate.
                </p>
                <h5>Disclaimer</h5>
                <p>
                  If Kano State Internal revenue Service (KIRS) discovers or
                  is of opinion at any time that a taxable person liable to
                  income tax has not been assessed or has been assessed at a
                  less amount which ought to have been changed, the relevant
                  tax authority may, within the year of Assesment or within
                  six year after the expiration thereof and as may be
                  necessary, access the taxable person at such amount or
                  additional amount as ought to have been charged ,and the
                  provisions of this act as to notice of assesment, appeal and
                  other proceedings shall apply to that assessment or
                  additional assessment and to the tax thereunder
                </p>
              </div>
            </CardBody>
          </Card>
        );
      default:
        return (
          <CardBody>
            <CardTitle tag="h5">Completion</CardTitle>
            <Label>All steps completed</Label>
          </CardBody>
        );
    }
  };

  return (
    <div>
      <Card>
        {showPDF ? <>
          <Modal size="xl" fullscreen as={Modal.Dialog} centered show={showPDF}>
            <Modal.Header>
              <Modal.Title className="h6">Invoice No.: {refNo}</Modal.Title>
              <Button variant="danger" className="bg-danger text-white" aria-label="Close" onClick={() => setShowPDF(false)}>
                X
              </Button>
            </Modal.Header>
            <Modal.Body>{refNo ? <PayAssessment refNo={refNo} /> : null}</Modal.Body>
          </Modal>
        </> : renderStep()}
        <div className="d-flex justify-content-between p-3">
          <Button
            disabled={step <= 1}
            onClick={() => setStep(step - 1)}
            className="w-25 step === 1 ? 'd-none' : ''"
            style={{
              backgroundColor: "#262B40",
              color: "white",
              border: "none",
              boxShadow: "none",
            }}
          >
            Previous
          </Button>
          {step == 5 ? (<>
            {
              directAssessment?.id ?
                <Button
                  className="w-25 "
                  style={{
                    backgroundColor: "#262B40",
                    color: "white",
                    border: "none",
                    boxShadow: "none",
                    float: "right",
                  }}
                  onClick={() => setShowPDF(true)}
                >
                  <FaFilePdf /> Proceed to payment
                </Button> :
                <CustomButton
                  loading={loading}
                  className="w-25 "
                  style={{
                    backgroundColor: "#262B40",
                    color: "white",
                    border: "none",
                    boxShadow: "none",
                    float: "right",
                  }}
                  onClick={handleSubmit}
                >
                  {loading ? ' Please wait' : 'Declare'}
                </CustomButton>

            }
          </>) : (
            <Button
              onClick={handleNext}
              className="w-25 "
              style={{
                backgroundColor: "#262B40",
                color: "white",
                border: "none",
                boxShadow: "none",
                float: "right",
              }}
            >
              NEXT
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};