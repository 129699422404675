import 'dotenv';
import { logout } from './auth';

// import { useHistory } from 'react-router-dom';
// let localEndpoint = "http://localhost:3589";
// let localEndpoint = "http://localhost:3589";
// let remoteEndpoint = "https://finance-apis-13e37c457a0e.herokuapp.com";
// let remoteTestEndpoint = "https://kirmas-test-931cfc93adfb.herokuapp.com";
// let localEndpoint = 'http://localhost:3589';
let localEndpoint = 'http://localhost:35899';
let remoteEndpoint = "https://galaxybis.ebudgetkano.ng/kirmas-test";
const apiURL = process.env.NODE_ENV === 'development' ? localEndpoint : remoteEndpoint;
export const nationalMinimumWage = 30000
const _postApi = (url, data = {}, success = (f) => f, error = (f) => f) => {
  let token = localStorage.getItem('@@auth_token');
  fetch(apiURL + url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', authorization: token },
    body: JSON.stringify(data),
  })
    .then((raw) => raw.json())
    .then((response) => {
      if (response.status >= 400) {
        error(response);
        if (response.status === 401) {
          logout();
          window.location.reload();
        }
      } else success(response);
    })
    .catch((err) => error(err));
};

const _fetchApi = (url, success = (f) => f, error = (f) => f, empty = (f) => f) => {
  let token = localStorage.getItem('@@auth_token');
  fetch(apiURL + url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', authorization: token },
  })
    .then((raw) => raw.json())
    .then((response) => {
      if (response) {
        success(response);
      } else {
        console.log('Empty response');
        empty();
      }
    })
    .catch((err) => {
      if (err.status === 401) {
        logout();
        window.location.reload();
      }
      error(err);
    });
  // alert(token);
};

//tax_parent_code tax_code
function unflatten(arr) {
  var tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for (var i = 0, len = arr?.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.tax_parent_code] = arrElem;
    mappedArr[arrElem.tax_parent_code]['children'] = [];
  }

  // Create the tree structure by iterating over the array again.
  for (let i = 0, len = arr?.length; i < len; i++) {
    arrElem = arr[i];
    mappedElem = mappedArr[arrElem.tax_parent_code];

    // Add a custom property to each child containing parent head values
    const parentHeads = [];
    let currentParent = mappedElem;
    while (currentParent?.tax_code) {
      parentHeads.unshift(currentParent?.tax_code);
      currentParent = mappedArr[currentParent?.tax_code];
    }
    const childWithProperty = { ...mappedElem, parentHeads };

    if (mappedElem?.tax_code) {
      mappedArr[`parent_${i}`] = '20';
      mappedArr[mappedElem?.tax_code]['children'].push(childWithProperty);
    } else {
      tree.push(childWithProperty);
    }
  }

  return tree;
}
// / System for American Numbering
var th_val = ['', 'thousand', 'million', 'billion', 'trillion'];

export const formatData = (list) => {
  if (list.length) {
    let totalAmount = list.reduce((a, b) => a + parseFloat(b.propose_quantity) * parseFloat(b.price), 0) || 0;
    let firstItem = list[0];
    let formTitle = {
      PONo: '',
      auditor_remark: '',
      client: '',
      date: '', //moment(firstItem.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      exchange_rate: 1,
      exchange_type: '',
      general_remark: '',
      process_by: 'admin-user',
      supplier_code: '39',
      total: totalAmount,
      type: 'Local',
      vendor: firstItem.vendor,
    };

    let tableData = [];

    list.forEach((item) => {
      tableData.push({
        exchange_rate: 1,
        exchange_type: '',
        expired_status: 'false',
        identifier: 'new',
        item_category: item.item_category,
        item_code: item.item_code,
        item_name: item.item_name,
        po_id: '',
        price: item.price,
        propose_amount: parseFloat(item.propose_quantity) * parseFloat(item.price),
        propose_quantity: item.propose_quantity,
        quantity_available: null,
        remarks: '',
        remarks_id: 1,
        specification: '',
        status: 'pending',
        type: 'Local',
      });
    });

    return {
      formTitle,
      tableData,
    };
  } else {
    return {};
  }
};

export function getStatusColor(status) {
  if (status === 'Recorded') {
    return '#22ad35';
  } else {
    return '#939832';
  }
}
// System for uncomment this line for Number of English
// var th_val = ['','thousand','million', 'milliard','billion'];

export function toWordsconver(s) {
  // / System for American Numbering
  var th_val = ['', 'thousand', 'million', 'billion', 'trillion'];
  // System for uncomment this line for Number of English
  // var th_val = ['','thousand','million', 'milliard','billion'];

  var dg_val = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  var tn_val = [
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];
  var tw_val = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  if (s) {
    s = s.toString() || 0;
    s = s.replace(/[\\, ]/g, '');
    if (s != parseInt(s)) return 'not a number ';
    var x_val = s.indexOf('.');
    if (x_val == -1) x_val = s.length;
    if (x_val > 15) return 'too big';
    var n_val = s.split('');
    var str_val = '';
    var sk_val = 0;
    for (var i = 0; i < x_val; i++) {
      if ((x_val - i) % 3 == 2) {
        if (n_val[i] == '1') {
          str_val += tn_val[Number(n_val[i + 1])] + ' ';
          i++;
          sk_val = 1;
        } else if (n_val[i] != 0) {
          str_val += tw_val[n_val[i] - 2] + ' ';
          sk_val = 1;
        }
      } else if (n_val[i] != 0) {
        str_val += dg_val[n_val[i]] + ' ';
        if ((x_val - i) % 3 == 0) str_val += 'hundred ';
        sk_val = 1;
      }
      if ((x_val - i) % 3 == 1) {
        if (sk_val) str_val += th_val[(x_val - i - 1) / 3] + ' ';
        sk_val = 0;
      }
    }
    if (x_val != s.length) {
      var y_val = s.length;
      str_val += 'point ';
      for (var e = x_val + 1; e < y_val; e++) str_val += dg_val[n_val[e]] + ' ';
    }
    return str_val.replace(/\s+/g, ' ');
  }
}
export function calculateComparison(grossIncome) {
  // Calculate 1% of the gross income
  const onePercent = grossIncome * 0.01;

  // Compare 1% with 200,000 and return the higher amount
  return Math.max(onePercent, 200000);
}
export function calculateOnPercent(grossIncome) {
  // Calculate 1% of the gross income
  const onePercent = grossIncome * 0.01;
  // Compare 1% with 200,000 and return the higher amount
  return onePercent
}

export function generateCRA(grossIncome, cgi) {
  let CRA1 = calculateComparison(formatToPureNumber(grossIncome));
  let CRA2 = cgi * 0.2;
  let CRA = CRA1 + CRA2;

  return CRA;
}
export function consolidatedReliefAllowance(pensionPercentage, nhfDeductionAmount, nhis, CRA) {
  return pensionPercentage + nhfDeductionAmount + nhis + CRA;
}
export function grossIncomeFun(other_allowance, basic_salary) {
  let basic = 12 * formatToPureNumber(basic_salary);
  let other = formatToPureNumber(other_allowance);
  //  console.log(other_allowance, basic_salary);
  return basic + other;
}
const formatToPureNumber = (num = '') => {
  const numStr = num?.toString();
  const reg = numStr?.replace(',', '');
  return Number(reg);
};

export const cgiFun = (grossIncome, pensionPercentage, nhfDeductionAmount, nhis) => {
  return (
    grossIncome -
    (formatToPureNumber(pensionPercentage) + formatToPureNumber(nhfDeductionAmount) + formatToPureNumber(nhis))
  );
};
export function taxableIncome(pensionPercentage, nhfDeductionAmount, nhis, other_allowance, basic_salary) {
  // get the gross income here
  let grossIncome = grossIncomeFun(formatToPureNumber(other_allowance), formatToPureNumber(basic_salary));

  // here is to find cgi
  let cgi = cgiFun(
    grossIncome,
    (formatToPureNumber(pensionPercentage), formatToPureNumber(nhfDeductionAmount), formatToPureNumber(nhis)),
  );

  // here is to fin CRA
  let CRA = generateCRA(grossIncome, cgi);

  // here is to find the consolidated relief allowance
  let consolidatedReliefA = consolidatedReliefAllowance(
    formatToPureNumber(pensionPercentage),
    formatToPureNumber(nhfDeductionAmount),
    formatToPureNumber(nhis),
    generateCRA(
      grossIncome,
      cgiFun(
        grossIncomeFun(formatToPureNumber(other_allowance), formatToPureNumber(basic_salary)),
        formatToPureNumber(pensionPercentage),
        formatToPureNumber(nhfDeductionAmount),
        formatToPureNumber(nhis),
      ),
    ),
  );

  let taxable_income = grossIncome - consolidatedReliefA;
  return taxable_income;
}
export function chargeableIncome(grossIncome) {
  return grossIncome - generateCRA(grossIncome);
}
export function calculateTax(taxableIncome) {
  let tax = 0;
  let remainingIncome = taxableIncome;
  let check = 1;
  while (remainingIncome > 0) {
    switch (check) {
      case 1:
        tax += Math.min(remainingIncome, 300000) * (7 / 100);
        remainingIncome -= 300000;
        break;
      case 2:
        tax += Math.min(remainingIncome, 300000) * (11 / 100);
        remainingIncome -= 300000;
        break;
      case 3:
        tax += Math.min(remainingIncome, 500000) * (15 / 100);
        remainingIncome -= 500000;
        break;
      case 4:
        tax += Math.min(remainingIncome, 500000) * (19 / 100);
        remainingIncome -= 500000;
        break;
      case 5:
        tax += Math.min(remainingIncome, 1600000) * (21 / 100);
        remainingIncome -= 1600000;
        break;
      default:
        tax += remainingIncome * (24 / 100);
        remainingIncome = 0;
    }
    check++;
  }

  console.log(tax);
  return tax;
}

export { _postApi, _fetchApi, apiURL, unflatten };
