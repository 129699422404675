import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  ButtonGroup,
  Table,
} from "@themesberg/react-bootstrap";
import { _fetchApi, _postApi } from "../redux/action/api";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomDateRange from "./dashboard/CustomDateRange";
import { downloadExcel } from "react-export-table-to-excel";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CollectionsPDF from "../components/CollectionsPDF";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Input, Label } from "reactstrap";
import CollectionsSummaryPDF from "../components/CollectionsSummaryPDF";
import { formatNumber, toCurrency } from "../utils";
import toast from "react-hot-toast";
import CustomTypeahead from "../components/UI/CustomTypeahead";
import { text } from "@fortawesome/fontawesome-svg-core";
import CollectionsSummaryPDF1 from "../components/CollectionsSummaryPDF1";

function MDASummaryReport() {
  const { user } = useSelector((s) => s.auth);
  const _form = {
    query_type: "view-items-summary",
    view: "receipt",
    limit: 20000,
    offset: 0,
    mda_name: "",
    mda_code: "",
  }
  const [form, setForm] = useState(_form);
  const [mdas, setMDAs] = useState([]);
  // [
  //   { name: 'TAX', label: 'Taxes', }, { name: 'NON TAX', label: 'Non Tax' }, { name: 'LAND', label: 'Land use charges' }, { name: 'VEHICLES', label: 'MLO' }
  // ];
  useEffect(() => {
    _fetchApi(
      "/get/mdas?query_type=get-mdas",
      (resp) => {
        if (resp.success) {
          setMDAs(resp.data);
        }
      },
      (err) => {
        console.error(err);
        toast.error("Errorr occured!");
      }
    );
  }, []);
  const lgStyle = {
    height: "80vh",
    overflowY: "scroll",
    scrollbarWidth: "thin", // For Firefox
    scrollbarColor: "transparent transparent", // For Firefox
    overflow: "-moz-scrollbars-none",
    zIndex: 1,
  };
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);
  // date picker setup
  const today = moment();
  const [dateRange, setDateRange] = useState({
    type: "daily",
    month: today.format("MM"),
    quarter: Math.ceil(today.format("MM") / 3),
    year: today.format("YYYY"),
    from: today.format("YYYY-MM-DD"),
    to: today.format("YYYY-MM-DD"),
  });

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const weekStart = moment().startOf("week").format("YYYY-MM-DD");
    const weekEnd = moment().endOf("week").format("YYYY-MM-DD");
    const monthStart = moment().startOf("month").format("YYYY-MM-DD");
    const monthEnd = moment().endOf("month").format("YYYY-MM-DD");
    const yearStart = moment().startOf("month").format("YYYY-MM-DD");
    const yearEnd = moment().endOf("month").format("YYYY-MM-DD");

    const qStart = moment(
      `'${dateRange.year}-${dateRange.quarter * 3 - 3}-01`
    ).format("YYYY-MM-DD");
    const qEnd = moment(`'${dateRange.year}-${dateRange.quarter * 3}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");

    switch (dateRange.type) {
      case "daily":
        setDateRange({ ...dateRange, from: today, to: today });
        break;
      case "weekly":
        setDateRange({ ...dateRange, from: weekStart, to: weekEnd });
        break;
      case "monthly":
        setDateRange({ ...dateRange, from: monthStart, to: monthEnd });
        break;
      case "quarterly":
        setDateRange({ ...dateRange, from: qStart, to: qEnd });
        break;
      case "yearly":
        setDateRange({ ...dateRange, from: yearStart, to: yearEnd });

      default:
        break;
    }
  }, [dateRange.type]);

  const handleChangeDate = ({ target: { name, value } }) => {
    switch (name) {
      case "month":
        const monthStart = moment(`'${dateRange.year}-${value}-01'`).format(
          "YYYY-MM-DD"
        );
        const monthEnd = moment(`'${dateRange.year}-${value}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: monthStart,
          to: monthEnd,
          month: value,
        });
        break;
      case "year":
        const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
        const yearEnd = moment(`'${value}-12'`)
          .endOf("year")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: yearStart,
          to: yearEnd,
        });
        break;

      case "quarter":
        let startMonth, endMonth;
        let selectedQuarter = value;
        switch (selectedQuarter) {
          case "Q1":
            startMonth = 1;
            endMonth = 3;
            break;
          case "Q2":
            startMonth = 4;
            endMonth = 6;
            break;
          case "Q3":
            startMonth = 7;
            endMonth = 9;
            break;
          case "Q4":
            startMonth = 10;
            endMonth = 12;
            break;
          default:
            // Handle unexpected quarter values
            break;
        }
        const qStart = moment(`'${dateRange.year}-${startMonth}-01'`).format(
          "YYYY-MM-DD"
        );
        const qEnd = moment(`'${dateRange.year}-${endMonth}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: qStart,
          to: qEnd,
          quarter: selectedQuarter,
        });
        break;
      case "from":
        setDateRange({
          ...dateRange,
          from: moment(value).format("YYYY-MM-DD"),
        });
        break;

      case "to":
        setDateRange({
          ...dateRange,
          to: moment(value).format("YYYY-MM-DD"),
        });
        break;
      default:
        break;
    }
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  useEffect(() => {
    setLoading(true);
    if (form.query_type) {
      _postApi(
        `/transactions/update-print-count?sector=${user.sector}`,
        {
          query_type: form.query_type,
          from: dateRange.from,
          to: dateRange.to,
          view: form.view,
          sector: user.sector,
          limit: form.limit,
          offset: form.offset,
          mda_code: form.mda_code ? form.mda_code : user.mda_code,
        },
        (resp) => {
          setLoading(false);
          if (resp && resp.data.length) {
            setReport(resp.data);
          } else {
            setReport([]);
          }
        },
        (err) => {
          setLoading(false);
          setReport([]);
          console.log(err);
        }
      );
    }
  }, [dateRange, form.limit, form.query_type, form.offset, form.mda_code]);

  const total = report.reduce((accumulator, current) => {
    const totalValue = parseFloat(current.total) || 0;
    return accumulator + totalValue;
  }, 0);

  const header = ["SN", "MDA", "DEPARTMENT", "REVENUE GENERATE"];
  const summary_header = [
    "SN",
    "DESCRIPTION",
    "FROM",
    "TO",
    "REVENUE GENERATED",
  ];
  const RECORDS = report?.length
    ? report?.map((t) => ({
      mda: t.mda_name,
      department: t.department,
      from: t.start_date,
      to: t.end_date,
      amount: t.total,
    }))
    : [];

  const SUMMARY = report?.length
    ? report.map((item, idx) => ({
      id: idx + 1,
      department: item.department,
      description: item.description,
      mda_name: item.mda_name,
      start_date: item.start_date,
      end_date: item.end_date,
      total: item.total,
    }))
    : [];
  function handleDownloadExcel() {
    downloadExcel({
      fileName: getFileName(form.query_type),
      sheet: getFileName(form.query_type),
      tablePayload: {
        header: form.query_type.includes("items") ? summary_header : header,
        body: form.query_type.includes("items") ? RECORDS : SUMMARY,
      },
    });
  }
  const handleChange = ({ target: { name, value } }) => {
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };
  const getFileName = (title) => {
    let text;
    switch (title) {
      case "view-items-summary":
        text = "Items Collection summary";
        break;
      case "view-summary":
        text = "Departments collection summary";
        break;
      case "departmental-receipt-summary":
        text = "Deparments receipt ssummary";
      case "items-receipt-summary":
        text = "Items Receipt Summary";
        break;
    }
    return text;
  };
  return (
    <div>
      <Row className="d-flex flex-direction-row justify-content-between align-items-end">
        {/* {JSON.stringify({ form })} */}
        <Col md={3}>
          {user.mda_name ? (
            <div className="mb-1">
              <Input type="text" value={user.mda_name} />
            </div>
          ) : (
            <CustomTypeahead
              label="MDA"
              name="sector"
              labelKey={(i) => `${i.mda_name}`}
              value={form.mda_name}
              options={mdas}
              onChange={(e) => {
                if (e.length) {
                  console.log(e[0]);
                  setForm((p) => ({
                    ...p,
                    mda_code: e[0].mda_code,
                    mda_name: e[0].mda_name,
                  }));
                }
              }}
              col={12}
            />
          )}
        </Col>
        <Col md={4}>
          <h4 className="my-1 fw-bold">
            <Input type="select" name="query_type" onChange={handleChange}>
              <option value="view-items-summary">Items Collections</option>
              <option value="view-summary">Departmental Collections</option>
              <option value="items-receipt-summary">Items Reciepts</option>
              <option value="departmental-receipt-summary">
                Departmental Reciepts
              </option>
            </Input>
          </h4>
        </Col>
        <Col className="text-right">
          <ButtonGroup>
            {/* <Button
              variant={
                form.view === "invoice"
                  ? "primary"
                  : "outline-primary"
              }
              onClick={() => viewContent("invoice")}
              size="sm"
              style={{}}
            >
              View Invoices
            </Button> */}
            {/* <Button
              variant={
                form.view === "receipt"
                  ? "primary"
                  : "outline-primary"
              }
              onClick={() => viewContent("receipt")}
              size="sm"
              style={{}}
            >
              View Paid
            </Button> */}
            <PDFDownloadLink
              document={
                form.query_type.includes("items") ? (
                  <CollectionsSummaryPDF
                    user={user}
                    form={form}
                    data={report}
                    title={getFileName(form.query_type)}
                  />
                ) : (
                  <CollectionsSummaryPDF1
                    user={user}
                    form={form}
                    data={report}
                    title={getFileName(form.query_type)}
                  />
                )
              }
              fileName={`${getFileName(form.query_type)}-${moment().format(
                "YYYYMMDDhhmm"
              )}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button
                    variant={
                      form.view === "PDF" ? "primary" : `outline-primary`
                    }
                    size="sm"
                    onClick={() => setForm((p) => ({ ...p, view: "PDF" }))}
                    style={{ borderRadius: "10px 0px 0px 0px" }}
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      size="sm"
                      className="me-2"
                    />
                    PDF
                  </Button>
                )
              }
            </PDFDownloadLink>
            <Button
              variant={form.view !== "Excel" ? "outline-primary" : "primary"}
              size="sm"
              onClick={() => {
                handleDownloadExcel();
                setForm((p) => ({ ...p, view: "Excel" }));
              }}
              style={{ borderRadius: "0px 0px 10px 0px" }}
            >
              Excel{" "}
              <FontAwesomeIcon icon={faDownload} size="sm" className="me-2" />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <div className="my-1">
        <CustomDateRange
          type={dateRange.type}
          month={dateRange.month}
          quarter={dateRange.quarter}
          year={dateRange.year}
          from={dateRange.from}
          to={dateRange.to}
          handleChangeDate={handleChangeDate}
        />
      </div>

      <div style={lgStyle}>
        {form.query_type.includes("items") ? (
          <Card.Body>
            <Row className="align-items-center">
              <Col className="text-left">
                {" "}
                From: {moment(report[0]?.start_date).format(
                  "D  MMMM, YYYY"
                )}{" "}
                to: {moment(report[0]?.end_date).format("D  MMMM, YYYY")}{" "}
              </Col>
              <Col className="text-right">
                Total:{" "}
                <i>
                  <b>₦{formatNumber(parseFloat(total).toFixed(2))} </b>
                </i>
              </Col>
            </Row>
            <Col md={12} className="">
              <table
                style={{ width: "100%" }}
                className="table table-responsive table-striped  pt-0"
              >
                <thead>
                  <tr>
                    <th className="border-bottom">#</th>
                    <th className="border-bottom">Description</th>
                    <th className="border-bottom">MDA</th>
                    <th className="border-bottom">Department</th>
                    <th className="border-bottom">Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {report.map((t, idx) => (
                    <tr key={idx}>
                      <td className="border-bottom">{idx + 1}</td>
                      <td className="border-bottom">{t.description}</td>
                      <td className="border-bottom">{t.mda_name}</td>
                      <td className="border-bottom">{t.department}</td>
                      <td className="border-bottom text-right">
                        {formatNumber(t.total)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Card.Body>
        ) : (
          <Card.Body>
            <div className="text-right">
              The sum of{" "}
              <b>
                <i>₦{toCurrency(parseFloat(total).toFixed(2))}</i>
              </b>{" "}
              was generated From:{" "}
              <b>{moment(report[0]?.start_date).format("D  MMMM, YYYY")}</b> to:{" "}
              <b>{moment(report[0]?.end_date).format("D  MMMM, YYYY")}</b>
            </div>
            <table
              style={{ width: "100%" }}
              className="table  table-responsive align-items-center table-zebra pt-0"
            >
              <thead>
                <tr>
                  <th className="border-bottom">#</th>
                  <th className="border-bottom">Department</th>
                  <th className="border-bottom">MDA</th>
                  <th className="border-bottom text-center">Receipts</th>
                  <th className="border-bottom text-right text-end">Revenue</th>
                </tr>
              </thead>
              <tbody>
                {report.map((t, idx) => (
                  <tr key={idx}>
                    <td className="border-bottom">{idx + 1}</td>
                    <td className="border-bottom">{t.department}</td>
                    <td className="border-bottom">{t.mda_name}</td>
                    <td className="border-bottom text-center">
                      {toCurrency(t.total_printed)}
                    </td>
                    <td className="border-bottom text-right text-end">
                      {formatNumber(t.total)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card.Body>
        )}
      </div>
    </div>
  );
}

export default MDASummaryReport;
