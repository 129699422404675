import React, { useEffect, useState } from "react";
import { Alert, Button, Card, CardHeader, Col, Row } from "reactstrap";
import SelectInput from "../components/SelectInput";
import CustomInput from "../examples/CustomInput";
import { CustomButton } from "../../components/UI";
import { _fetchApi, _postApi } from "../../redux/action/api";
import { toast } from "react-hot-toast";
import ReceiptPreview from "../../components/ReceiptPreview";

export default function VerifyPayNow() {
  const [form, setForm] = useState({
    sector: "",
    amount: "",
    ref_no: "",
  });
  const merchantCode = "60969";
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const [data, setData] = useState();
  const getData = () => {
    setLoading(true);
    _fetchApi(
      `/verify-paynow-by-reference?reference_number=${form.ref_no}`,
      (res) => {
        if (res.success) {
          setData(res.results);
        }
        console.log(res.results);
        setLoading(false);
      },
      (err) => {
        console.error(err);
        setLoading(false);
      }
    );
  };

  let mydata = data && data.filter((item) => item.dr > 0);
  const total = data?.reduce((acc, curr) => {
    return acc + parseFloat(curr.dr);
  }, 0);
  let new_amount = total * 100;
  let input_amount = new_amount?.toString().split(".")[0];
  let payItemID = null;
  switch (mydata?.sector) {
    case "VEHICLES":
      payItemID = "7035882";
      break;
    case "LGA":
      payItemID = "4351942";
      break;
    case "LAND":
      payItemID = "6762266";
      break;
    case "NON TAX":
      payItemID = "6160982";
      break;
    case "TAX":
      payItemID = "5775505";
      break;

    default:
      break;
  }

  const handleResponse = () => {
    setLoading(true);
    fetch(
      `https://webpay.interswitchng.com/collections/api/v1/gettransaction.json?merchantcode=MX${merchantCode}&transactionreference=${form.ref_no
      }&amount=${Math.floor(total * 100)}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((raw) => raw.json())
      .then((resp) => {
        if (resp) {
          setLoading(false);
          setResponse(resp);

          console.log(resp);
          console.log("resp");
          if (resp.ResponseCode === "00") {
            setLoading(true);
            _postApi(
              "/inter-response",
              { ...resp },
              (res) => {
                console.log(res);
                if (res.success) {
                  console.log({ ...resp });
                  setLoading(false);
                  toast.success(resp.ResponseDescription);
                  // setForm({
                  //   sector: "",
                  //   amount: "",
                  //   ref_no: "",
                  // });
                } else {
                  setLoading(false);
                  // setForm({
                  //   sector: "",
                  //   amount: "",
                  //   ref_no: "",
                  // });
                }
              },
              (err) => {
                console.log(err);
                toast.error("Error occured while saving the payment update ");
                setLoading(false);
              }
            );
          } else {
            toast.success(resp.ResponseDescription);
          }
        } else {
          toast.error("Reference number not found");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occurred");
        setLoading(false);
      });
  };

  return (
    <div className="d-flex p-2  justify-content-center flex-wrap flex-md-nowrap align-items-center py-4 pt-5">
      <Card className="shadow col-md-6 px-4 py-5 ">
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <center>
            <h4>Verify Pay Now</h4>
          </center>
        </div>
        {JSON.stringify(mydata?.reference_number)}
        {data ? (
          <>
            <ReceiptPreview data={data} />
            <CustomButton
              loading={loading}
              onClick={data ? handleResponse : getData}
            >
              Verify
            </CustomButton>
          </>
        ) : (
          <Row>
            {/* <Col md={12}>
            <SelectInput
              label="Sector"
              type="text"
              name="sector"
              value={form.sector}
              onChange={handleChange}
              options={["TAX", "NON TAX", "VEHICLES", "LAND", "LGA"]}
            />
          </Col>
          <Col md={12}>
            <CustomInput
              label="Amount"
              type="text"
              name="amount"
              value={form.amount}
              onChange={({ target: { name, value } }) => {
                setForm((p) => ({ ...p, [name]: value.replace(/,/g, "") }));
              }}
            />
          </Col> */}

            <Col
              md={12}
              style={{
                marginTop: -20,
              }}
            >
              <CustomInput
                placeholder="Reference Number"
                type="text."
                name="ref_no"
                value={form.ref_no}
                onChange={handleChange}
                className="text-center"
              />
            </Col>
            <Col md={12}>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <CustomButton
                  loading={loading}
                  onClick={mydata && mydata ? handleResponse : getData}
                >
                  Verify
                </CustomButton>
              </div>
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
}
