// AUTHS
export const LOADING_SIGNUP = "LOADING_SIGNUP";
export const AUTH = "SET_USER";
export const TAX_ACCOUNT = "TAX_ACCOUNT";
export const TAX_ACCOUNTS = "TAX_ACCOUNTS";
export const LOADING_LOGIN = "LOADING_LOGIN";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const LOADING_APP = "___LOADING_APP";
export const ERRORS = "ERRORS";
export const PAYER_ID = "PAYER_ID";
export const RECOVER_USER = "RECOVER_USER";

// FINANCE (PAYMENTS)
export const PAYMENT_SCHEDULE = "Payment-Schedule";
export const PAYMENT_SCHEDULES = "Payment-Schedules";
export const BATCH_NO = "BATCH_NO-Schedules";
export const APPROVAL_DISPHATCH = "APPROVAL_DISPHATCH";

//KIGRA TYPES
export const ADD_PAYMENT = "ADD_PAYMENT";
export const EMPTY_CART = "EMPTY_CART";
