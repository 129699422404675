export const Routes = {
  // pages
  // Presentation: { path: "/" },
  DashboardOverview: { path: "/dashboard/overview" },
  NonTaxPayment: { path: "/user/dashboard/non-tax-payments" },
  VehiclePayments: { path: "/user/dashboard/vehicle-payments" },
  CreateTax: { path: "/admin/dashboard/create-tax" },
  TaxPayment: { path: "/user/dashboard/tax-payments" },
  LgaPayments: { path: "/user/dashboard/lga-payments" },
  LandPayments: { path: "/user/dashboard/land-payments" },
  AdminSetup: { path: "/admin/dashboard/admins-manager" },
  Transactions: { path: "/transactions" },
  TaxPayerProfile: { path: "/admin/dashboard/tax-payer" },
  UserProfile: { path: "/user/dashboard/profile" },
  Profile: { path: "/user/profile" },
  UserTransactions: { path: "/user/dashboard/transactions" },
  ViewInvoice: { path: "/user/dashboard/viewinvoice" },
  Withholding: { path: "/user/dashboard/withholding" },
  EmployeeRegistration: { path: "/user/dashboard/employee-registration" },
  FilingWithholding: { path: "/user/dashboard/filing" },
  UploadPayroll: { path: "/user/dashboard/upload-payroll" },
  RequestTCC: { path: "/user/dashboard/request-tcc" },
  NewResetPassword: { path: "/user/resetpassword-new" },
  selectedTax: { path: "/user/self-assessment" },
  AdminResetPassword: { path: "/resetpassword-new" },
  PayeSalaryType: { path: "/user/paye-salary-type" },
  PayeDateRange: { path: "/user/paye-date-range" },
  PayeSetup: { path: "/user/paye-setup" },
  RaiseRequest: { path: "/raise-request" },
  RequestConfirmation: { path: "/request-confirmation" },
  FinanlApproval: { path: "/finanl-approval" },
  PeriodAdjustment: { path: "/period-adjustment" },
  TaxPayerTransactions: { path: "/admin/dashboard/tax-payer-trx" },
  TCCDashboard: { path: "/admin/dashboard/tcc-dashboard" },
  ReportTransaction: { path: "/report-transaction" },
  TaxPayers: { path: "/tax-report" },
  viewTax: { path: "/view-report" },
  printInvoice: { path: "/print-invoice" },
  paymentInvoice: { path: "/payment-invoice" },
  adminPaymentInvoice: { path: "/dashboard/payment-invoice" },
  adminPaymentReceipt: { path: "/dashboard/payment-receipt" },
  paymentReceipt: { path: "/payment-receipt" },
  // adminPaymentReceipt: { path: "/admin/payment-receipt" },
  ReceiptAdmin: { path: "/admin/payment-receipt" },
  Selection: { path: "/selection" },
  Dashboard: { path: "/dashboard" },
  TaxType: { path: "/taxtype" }, //taxtype
  OnlinePayment: { path: "/online-payment" },

  MDAReport: { path: "/report-mdas" },
  PaymentLogs: { path: "/receipt-logs" },
  ReceiptReport: { path: "/receipt-report" },
  CollectionReport: { path: "/collection-report" },
  InvoiceReport: { path: "/invoice-report" },
  TaxClearancePDF: { path: "/tax-clearance-pdf" },
  ReceiptReportDetails: { path: "/receipt-report-details" },
  MDAReportTransactions: { path: "/report-mdas/:mda" },
  Settings: { path: "/settings" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  Signin: { path: "/" },
  // ResetPas: { path: "/reset-pas" },
  Signup: { path: "/sign-up" },
  Verify: { path: "/admin/verify" },
  getVerify: { path: "/admin/get-verify/:id" },
  Viewverify: { path: "/viewverify/:id" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/user/reset-password" },
  Filling: { path: "/user/filling" },
  MyTccAccount: { path: "/user/my-tcc-account" },
  Organization: { path: "/user/organization" },
  PayGroup: { path: "/user/pay-group" },
  AssessmentTableCard: { path: "/user/assessment-table" },
  FillingPaye: { path: "/user/filling-paye" },
  ResetPass: { path: "/reset-password" },
  RecoverCode: { path: "/recover-code" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },

  // docs
  DocsOverview: { path: "/documentation/overview" },
  DocsDownload: { path: "/documentation/download" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  DocsLicense: { path: "/documentation/license" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },

  //Tax Assesment

  NominalRollUpload: { path: "/user/nominal-roll-upload" },
  PendingTax: { path: "/user/pending-tax" },
  Apply: { path: "/user/apply" },
  Payee: { path: "/user/Payee" },
  Employee: { path: "/user/Employee" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  ViewConsultant: { path: "/components/view-consultant" },
  // ViewConsultant
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
  TaxMngr: { path: "/admin/dashboard/tax-mngr" },
  TaxMngrTable: { path: "/admin/dashboard/tax-mngr-table" },
  NonTaxSetting: { path: "/admin/dashboard/non-tax-setting" },
  VehicleTaxSetting: { path: "/admin/dashboard/vichicle-tax-setting" },
  LandTaxSetting: { path: "/admin/dashboard/land-tax-setting" },
  LGATaxSetting: { path: "/admin/dashboard/lga-tax-setting" },
  GenerateReceipt: { path: "/admin/dashboard/generate-receipt" },
  ExpressAcct: { path: "/admin/dashboard/express-user" },
  ExpressAcct2: { path: "/user/express" },
  PayInvoice1: { path: "/user/pay-invoice" },
  VerifyPayNow: { path: "/user/verify-pay-now" },
  Tree: { path: "/admin/dashboard/tax-settings" },
  // Agent admin routesele
  AgentGenerateInvoice: { path: "/admin/dashboard/generate-invoice" },
  CreateTaxPayer: { path: "/admin/dashboard/create-tax-payer" },

  // Dont touch the following route is for Developer Admin:
  TaxManager: { path: "/admin/dashboard/tax-manager" },
  TaxCodeManager: { path: "/admin/dashboard/tax-code-manager" },
  AdminsMngr: { path: "/admin/dashboard/admins-manager" },
  AgentTransactions: { path: "/agent/history" },
  GenerateReports: { path: "/admin/gen.reports" },
  BankReport: { path: "/admin/banks-reports" },
  TaxReport: { path: "/admin/tax-reports" },
  NonTaxReport: { path: "/admin/nontax-reports" },
  LGAReport: { path: "/admin/lga-reports" },
  LandReport: { path: "/admin/land-reports" },
  VehicleReport: { path: "/admin/mlo-reports " },
  SummaryReports: { path: "/admin/summary-reports" },
  PayInvoice: { path: "/admin/pay-invoice" },
  VerifyPayNow1: { path: "/admin/verify-pay-now" },
  Departments: { path: "/admin/dashboard/depatments" },
  TaxClearance: { path: "/admin/dashboard/tax-clearance" },
  TaxClearanceTab: { path: "/admin/dashboard/tax-clearance-tab" },
  ClearanceApproval: { path: "/admin/dashboard/clearance-approval" },
  ClearancePrinting: { path: "/admin/dashboard/clearance-printing" },
  PrintClearance: { path: "/admin/dashboard/print-clearance" },
  TCCRecommendation: { path: "/admin/dashboard/clearance-recommendation" },
  TCCPrinting: { path: "/admin/dashboard/clearance-printing" },

  // Clearance
  OrgDashboard: { path: "/user/dashboard/org-dashboard" },
  AdminAssessment: { path: "/assesment/admin-dashboard" },
  OrgLiabilities: { path: "/assesment/org-liabilities" },
  OrgLedger: { path: "/assesment/org-ledger" },
  StaffLedger: { path: "/assesment/staff-ledger" },
  StaffLedgerUser: { path: "/user//assesment/staff-ledger" },
  UnpaidOrgs: { path: "/assesment/unpaid-orgs" },
  AllOrgs: { path: "/assesment/all-orgs" },
  NewDashboard: { path: "/assesment/revenue-dashboard" },
  UserDashboard: { path: "/user/dashboard/my-dashboard" },
  StaffPayeFilling: { path: "/user/paye-assessment" },
  OrgStaff: { path: "/user/staff-manager" },
};
