import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    CardText,
    Table,
    Input,
    Label,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Button,
    Badge,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faCloudDownloadAlt, faPaste, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { _fetchApi, _postApi } from '../redux/action/api';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { toCurrency } from '../utils';
import { Routes } from '../routes';
import { useHistory } from 'react-router-dom';
import ScheduleCalendar from './assesment/PaymentSchedule';
import { Modal } from '@themesberg/react-bootstrap';

export default function Organization() {
    const { user } = useSelector((s) => s.auth);
    const [scheduleData] = useState([]);
    const history = useHistory();
    const responsiveStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 'calc(13px + 1vw)',
        maxWidth: '100%',
    };
    const tableHeaderStyle = {
        fontWeight: '700',
        // border: "2px solid lightgray",
        color: 'black',
        fontSize: '12px',
    };

    const [assessments, setAssessments] = useState([]);
    const [show, setShow] = useState(true)

    useEffect(() => {
        if (user.taxID) {
            _fetchApi(
                `/assessments/paye-list?org_id=${user.taxID}&year=${moment().format('YYYY')}&query_type=monthly-tax`,
                (resp) => {
                    if (resp.success && resp.data.length) {
                        setAssessments(resp.data);
                    } else {
                        toast.error('Record not found!');
                    }
                },
            );
        }
    }, [user.taxID]);
    return (
        <div className="container">
            <div className="row mb-3">
                <div className="col-md-4">
                    <Card style={{ height: '100px' }}>
                        <CardBody
                            style={{
                                display: 'flex',
                            }}
                        >
                            <FontAwesomeIcon icon={faUserCircle} size="3x" className="me-4" />
                            <div
                                style={{
                                    alignItems: 'center',
                                }}
                            >
                                <CardTitle tag="h6" className="mb-1">
                                    Kirmas ID{' '}
                                </CardTitle>
                                <CardText tag="h3" style={responsiveStyle}>
                                    {user.taxID}
                                </CardText>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card style={{ height: '100px' }}>
                        <CardBody
                            style={{
                                display: 'flex',
                            }}
                        >
                            <FontAwesomeIcon icon={faAward} size="3x" className="me-4" />
                            <div style={{}}>
                                <CardTitle tag="h6" className="mb-1">
                                    No. of Employee{' '}
                                </CardTitle>
                                <CardText tag="h3">3</CardText>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card style={{ height: '100px' }}>
                        <CardBody
                            style={{
                                display: 'flex',
                            }}
                        >
                            <FontAwesomeIcon icon={faPaste} size="3x" className="me-4" />
                            <div
                                style={{
                                    alignItems: 'center',
                                }}
                            >
                                <CardTitle tag="h6" className="mb-1">
                                    TIN
                                </CardTitle>
                                <CardText tag="h3"></CardText>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>

            <Card body>
                <CardTitle className="h4 mt-0 mb-3 text-center">PAYE Payment Schedule</CardTitle>
                {scheduleData && Object.keys(scheduleData).length ? (
                    <ScheduleCalendar data={[]} hideUnpaid />
                ) : (
                    <div
                        className="text-center rounded pt-3 pb-1"
                    // style={{ background: theme.primary.main.backgroundColor }}
                    >
                        <p style={{ color: 'white' }}>No data found.</p>
                    </div>
                )}
            </Card>

            <div className="row">
                {/* {JSON.stringify(user)} */}
                <Card>
                    <div style={{ marginLeft: '-12px', marginRight: '-12px' }}>
                        <CardHeader tag="h5" className="p-3 bg-light">
                            Invoices
                        </CardHeader>
                    </div>
                    <CardBody>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Period</th>
                                    <th>No. of Staff</th>
                                    <th>Amount (₦)</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {/* {JSON.stringify(assessments)} */}
                            <tbody>
                                {assessments?.length ? assessments.map((assessment, idx) => (
                                    <tr key={idx}>
                                        <td>{`${assessment.payment_year}/${assessment.payment_month}`}</td>
                                        <td>{assessment.total_staff}</td>
                                        <td>{toCurrency(assessment.total_paid)}</td>
                                        <td>
                                            <Badge variant={assessment.status == 'pending' ? 'red' : 'green'}>
                                                {assessment.status}
                                            </Badge>
                                        </td>
                                        <td>
                                            <Button
                                                onClick={() =>
                                                    history.push(
                                                        `${Routes.OrgLiabilities.path}?org_kirmas_id=${assessment.org_id}`,
                                                    )
                                                }
                                                size="sm"
                                            >
                                                View
                                            </Button>
                                        </td>
                                    </tr>
                                )) : null}
                            </tbody>
                        </Table>
                    </CardBody>
                    {/* <Modal size="xl" fullscreen as={Modal.Dialog} centered show={show}>
                        <Modal.Header>
                            <Modal.Title className="h6">NOTICE</Modal.Title>
                            <Button variant="danger" className="bg-danger text-white" aria-label="Close" onClick={() => setShow(false)}>
                                X
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <h3>We are uprading the User Interface of our system</h3>
                            <h4>If you want to use the old Invoicing click <a onClick={history.push(Routes.Selection.path)} href="#">Here</a></h4>
                        </Modal.Body>
                    </Modal> */}
                </Card>
            </div>
        </div>
    );
}
