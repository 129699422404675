import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../assets/img/kano.png";
import kigra from "../assets/img/kigra.jpg";
import moment from "moment";
import { formatNumber, toCurrency } from "../utils";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    flexGrow: 1,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    padding: 4,
    borderLeftColor: "#333",
    borderLeftWidth: 0.5,
    fontSize: 11,
    fontWeight: "700",
    textAlign: "justify",
  },
  header: {
    fontSize: 23,
    textAlign: "center",
    marginTop: 30,
    marginBottom: 10,
  },
  header2: {
    fontSize: 15,
  },
  logo: {
    width: "40px",
    height: "40px",
  },
  logo1: {
    width: "300px",
    height: "300px",
  },
  watermark: {
    display: "flex",
    opacity: 0.1,
    position: "absolute",
    top: 200,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10, // Add some top margin for spacing
    marginBottom: 10, // Add some bottom margin for spacing
    paddingLeft: 10, // Add some left padding for spacing
    paddingRight: 10, // Add some right padding for spacing
  },
});

const CollectionsSummaryPDF = ({
  data = [],
  user = {},
  form = {},
  title = "",
}) => {
  const total = data.reduce((it, id) => it + parseFloat(id.total), 0);

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.page}>
        {/* <View style={styles.watermark}>
          <Image src={logo} style={styles.logo1} />
        </View> */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
          }}
        >
          <View style={styles.logoContainer}>
            <Image src={kigra} style={styles.logo} />
          </View>
        </View>
        <View style={styles.section}>
          <Text style={[styles.header]}>
            KANO INTEGRATED REVENUE MANAGEMENT AND ASSURANCE SYSTEM (KIRMAS)
          </Text>
          <View style={styles.header2}>
            <Text
              style={[
                styles.header,
                { fontSize: "18px", marginTop: 0, marginBottom: 5 },
              ]}
            >
              {title?.toLocaleUpperCase()}
            </Text>
          </View>
          <View style={styles.header2}>
            <Text>MDA: {user.mda_name}</Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Text>TOTAL: NGN {formatNumber(total)}</Text>
              <Text>
                FROM:{moment(data[0]?.start_date).format("D MMMM, YYYY")}
              </Text>
              <Text>
                FROM:{moment(data[0]?.end_date).format("D MMMM, YYYY")}
              </Text>
            </View>
          </View>
          <View style={styles.table}>
            {/* Table Headers */}
            <View style={styles.row}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "5.3%",
                    flexGrow: 0,
                    textAlign: "center",
                    fontWeight: "bold",
                  },
                ]}
              >
                <Text>#</Text>
              </View>
              <View
                style={[
                  styles.cell,
                  { width: "30.0%", textAlign: "center", fontWeight: "bold" },
                ]}
              >
                <Text>DESCRIPTION</Text>
              </View>
              <View
                style={[
                  styles.cell,
                  { width: "35%", textAlign: "center", fontWeight: "bold" },
                ]}
              >
                <Text>MDA</Text>
              </View>
              <View
                style={[
                  styles.cell,
                  { width: "15%", textAlign: "center", fontWeight: "bold" },
                ]}
              >
                <Text> DEPARTMET</Text>
              </View>
              <View
                style={[
                  styles.cell,
                  { width: "15%", textAlign: "center", fontWeight: "bold" },
                ]}
              >
                <Text>SUB TOTAL</Text>
              </View>
            </View>
            {data.map((row, index) => (
              <View key={index} style={styles.row}>
                <View
                  style={[styles.cell, { width: "5%", textAlign: "center" }]}
                >
                  <Text>{index + 1}</Text>
                </View>

                <View style={[styles.cell, { width: "30%" }]}>
                  <Text>{row.description}</Text>
                </View>

                <View style={[styles.cell, { width: "35%" }]}>
                  <Text>{row.mda_name}</Text>
                </View>
                <View style={[styles.cell, { width: "14%" }]}>
                  <Text>{row.department}</Text>
                </View>
                <View
                  style={[styles.cell, { width: "15%", textAlign: "right" }]}
                >
                  <Text>{formatNumber(row.total)}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CollectionsSummaryPDF;
