import React, { forwardRef, useEffect, useState } from "react";
import CustomInput from "./examples/CustomInput";
import { Card } from "@material-ui/core";
import { Button, CardBody, CardHeader, Col, Row, Spinner } from "reactstrap";
import { Modal } from "@themesberg/react-bootstrap";
import { useDispatch } from "react-redux";
import { expressSignup, getTaxPayerInfo } from "../redux/action/auth";
import { useHistory } from "react-router-dom";
import { ButtonGroup } from "@themesberg/react-bootstrap";
import { Routes } from "../routes";
import { toast } from "react-hot-toast";
import { stateLga } from "../statelga";
import SelectInput from "./components/SelectInput";
import CustomTypeahead from "../components/UI/CustomTypeahead";
import { TAX_ACCOUNT } from "../redux/action/type";
import { _fetchApi, _postApi } from "../redux/action/api";
// import { SelectInput } from "../components/UI";
// import CustomInput from "../examples/CustomInput";

const AddAccout = (props) => {
  const {
    tax_id = 0,
    account_type = "individual",
    isModal = false,
    isAddAccount = false,
    handleFocus = (f) => f,
  } = props;
  const [form, setForm] = useState({
    contact_name: "",
    name: "",
    contact_phone: "",
    contact_address: "",
    password: "123456",
    account_type,
    ward: "",
    state: "Kano",
    lga: "",
    idetifier: false,
    tax_id,
  });
  // const [type, setType] = useState(true);
  const [loading, setLoading] = useState(false);
  const onChanges = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value, idetifier: true }));
  };
  const [selectedAccount, setSelectedAccount] = useState({});
  const [tax_payers, setTaxPayers] = useState([]);
  const [tax_payer, setTapayer] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [taxID, setTaxID] = useState(null);
  const [showDefault, setShowDefault] = useState(false);

  useEffect(() => {
    if (tax_payer)
      _fetchApi(
        `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
        (resp) => {
          if (resp.success) {
            setTaxPayers(resp.data);
          }
        }
      );
  }, [tax_payer]);

  useEffect(() => {
    if (tax_id) {
      _fetchApi(`/users/get-tax-payer-info?user_id=${tax_id}`, (resp) => {
        if (resp.success) {
          setForm((p) => ({
            ...p,
            ...resp.data,
            tax_id: null,
            contact_name: resp.data.name,
            contact_phone: resp.data.phone,
            contact_address: resp.data.address,
          }));
          if (resp?.data.phone === "") {
            // setType(false);
          }
          setSelectedAccount(resp.data);
        }
      });
    }
  }, [tax_id]);

  const handleApi = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      form.contact_name === "" ||
      form.contact_phone === "" ||
      (form.contact_address === "" && form.office_address === "") ||
      form.idetifier === false
    ) {
      toast.error("Please complete the form");
      setLoading(false);
      return;
    } else {
      if (tax_id) {
        _postApi("/auths/-post-users", { ...form, taxID: tax_id }, (result) => {
          // setLoading(false);
          // console.log(result);
          if (result.success) {
            setLoading(false);
            toast.success("Tax payer has been updated");
            handleFocus();
          } else {
            setLoading(false);
            console.error(result); // Log the error for debugging
            toast.error("Submission failed");
          }
        });
      } else
        dispatch(
          expressSignup(
            {
              ...form,
              contact_name: form.contact_name,
              name: form.contact_name,
              query_type: isAddAccount ? "add-account" : "insert",
              org_name: form.account_type === "org" ? form.contact_name : "",
            },
            (result) => {
              setLoading(false);
              if (result.success) {
                setTaxID(result.taxID);
                setShowDefault(true);
              } else {
                toast.error(result.msg);
              }
            },
            (err) => {
              setLoading(false);
              console.log(err.msg);
              toast.error(err?.msg || "Error occured");
            }
          )
        );
    }
  };
  return (
    <div>
      {/* {JSON.stringify(form)} */}
      {showDefault ? (
        <div>
          {/**<div as={Modal.Dialog} centered show={showDefault}
        //   style={{ zIndex: showDefault ? 999999 : 1 }}>
        //   <Modal.Header>
      //     <Modal.Title className="h6"> **/}
          {form.tax_id ? (
            <h4 className="text-center">
              {" "}
              Tax Account has been updated successfully
            </h4>
          ) : (
            <h4 className="text-center"> Your Account created successfully</h4>
          )}
          {/** </Modal.Title >
          <Button variant="close" aria-label="Close" onClick={toggle} />
          // </Modal.Header>
    // <Modal.Body> **/}

          {isAddAccount ? (
            <h2 className="text-center">
              New account has been added to your TaxID ({taxID})
            </h2>
          ) : (
            <h2 className="text-center">Your KIRMAS TaxID is: {taxID}</h2>
          )}
          {/**  </div> </Modal.Body>
          // <Modal.Footer>
           **/}
          <Col className="text-center">
            <Button
              onClick={() => {
                if (isModal) {
                  handleFocus(taxID);
                  // ref.current.focus();
                } else {
                  dispatch(
                    getTaxPayerInfo(taxID, () => {
                      setLoading(false);
                      history.push(`${Routes.Selection.path}`);
                    }),
                    () => {
                      setLoading(false);
                      toast.error("Failed!");
                    }
                  );
                }
              }}
            >
              Proceed
            </Button>
          </Col>

          {/* <Button variant="link" className="text-gray ms-auto" onClick={toggle}>
            Close
          </Button>
          </Modal.Footer> */}
        </div>
      ) : (
        <>
          {isModal ? (
            <>
              {isAddAccount ? (
                <Row>
                  <Col md={12} className="mb-4">
                    <CustomTypeahead
                      onInputChange={(e) => {
                        setTapayer(e);
                      }}
                      labelKey={(e) =>
                        `${e.account_type === "org" ? e.org_name : e.name}(${e.taxID
                        }) ${e.phone ? `:${e.phone || e.office_phone}` : ""}`
                      }
                      onChange={(e) => {
                        if (e && e[0]?.id) {
                          dispatch({
                            type: TAX_ACCOUNT,
                            payload: e[0],
                          });
                          setForm((p) => ({
                            ...p,
                            // contact_name: e[0].name,
                            contact_phone: e[0].phone,
                            taxID: e[0].taxID,
                            user_id: e[0].user_id,
                            idetifier: true,
                          }));
                        }
                      }}
                      clearButton
                      col={12}
                      label={`Select Main Account `}
                      options={tax_payers}
                      placeholder="Search Name | TaxID | Phone No."
                    />
                  </Col>
                </Row>
              ) : null}
              <ButtonGroup className="w-100 pb-4">
                <Button
                  outline={form.account_type === "org"}
                  onClick={() =>
                    setForm((p) => ({ ...p, account_type: "individual" }))
                  }
                  color="dark"
                  className={`col-md-6`}
                >
                  INDIVIDUAL
                </Button>
                <Button
                  color="dark"
                  outline={form.account_type === "individual"}
                  onClick={() =>
                    setForm((p) => ({ ...p, account_type: "org" }))
                  }
                  className="col-md-6"
                >
                  ORGANIZATION/COMPANY
                </Button>
              </ButtonGroup>
              {/* {JSON.stringify(selectedAccount)} */}
              <Row>
                <Col md={6}>
                  <CustomInput
                    label="Enter Phone No."
                    name="contact_phone"
                    value={form.contact_phone}
                    onChange={onChanges}
                    disabled={tax_id > 0}
                    required={true}
                  />
                </Col>
                <Col md={6}>
                  <CustomInput
                    label="Tax payer name"
                    name="contact_name"
                    value={form.contact_name}
                    onChange={onChanges}
                    disabled={tax_id > 0}
                    required={true}
                  />
                </Col>
                <Col md={6}>
                  <CustomInput
                    type="textarea"
                    required={true}
                    label={
                      form.account_type === "org" ? "Office Address" : "Address"
                    }
                    name={
                      form.account_type === "org"
                        ? "office_address"
                        : "contact_address"
                    }
                    value={
                      form.account_type === "org"
                        ? form.office_address
                        : form.contact_address
                    }
                    onChange={onChanges}
                  />
                </Col>
                <Col md={6}>
                  <SelectInput
                    label="State of residence"
                    type="text"
                    name="state"
                    // required={true}
                    options={stateLga.map((item) => item.state)}
                    value={form.state}
                    onChange={onChanges}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-1">
                  {/* {JSON.stringify(s)} */}
                  <SelectInput
                    label="L.G.A"
                    type="text"
                    name="lga"
                    // required={true}
                    options={
                      stateLga.filter((item) => item.state === form.state)[0]
                        ?.lgas
                    }
                    value={form.lga}
                    onChange={onChanges}
                  />
                </Col>
                <Col md={6} className="mb-1">
                  <CustomInput
                    label="Ward"
                    type="text"
                    name="ward"
                    value={form.ward}
                    onChange={onChanges}
                  />
                </Col>
              </Row>
              <center>
                {" "}
                <Button onClick={handleApi} disabled={loading}>
                  {" "}
                  {loading ? <Spinner size="sm" /> : null}
                  {isAddAccount
                    ? "Add account"
                    : tax_id > 1
                      ? "Submit"
                      : "Create"}
                </Button>
              </center>
            </>
          ) : (
            <Card>
              <CardHeader>Express Account</CardHeader>
              <CardBody>
                <ButtonGroup className="w-100 pb-4">
                  <Button
                    outline={form.account_type === "org"}
                    onClick={() =>
                      setForm((p) => ({ ...p, account_type: "individual" }))
                    }
                    color="dark"
                    className={`col-md-6`}
                  >
                    INDIVIDUAL
                  </Button>
                  <Button
                    color="dark"
                    outline={form.account_type === "individual"}
                    onClick={() =>
                      setForm((p) => ({ ...p, account_type: "org" }))
                    }
                    className="col-md-6"
                  >
                    ORGANIZATION/COMPANY
                  </Button>
                </ButtonGroup>
                <Row>
                  <Col md={6}>
                    <CustomInput
                      label="Enter Phone No."
                      name="contact_phone"
                      value={form.contact_phone}
                      disabled={tax_id > 0 && selectedAccount.contact_phone}
                      onChange={onChanges}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomInput
                      label="Tax payer name"
                      name="contact_name"
                      value={form.contact_name}
                      onChange={onChanges}
                      disabled={tax_id > 0 && selectedAccount.name}
                      required={true}
                    />
                  </Col>
                  <Col md={12}>
                    <CustomInput
                      type="textarea"
                      label={
                        form.account_type === "org"
                          ? "Office Address"
                          : "Address"
                      }
                      name={
                        form.account_type === "org"
                          ? "office_address"
                          : "contact_address"
                      }
                      value={
                        form.account_type === "org"
                          ? form.office_address
                          : form.contact_address
                      }
                      required={true}
                      onChange={onChanges}
                    />
                  </Col>
                </Row>
                <center>
                  <Button onClick={handleApi} disabled={loading}>
                    {loading ? <Spinner size="sm" /> : null} Create
                  </Button>
                </center>
              </CardBody>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default AddAccout;
