import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card, Image } from "@themesberg/react-bootstrap";
import logo from "../assets/img/kirmas.png";
import useIsPhoneSize from "../utils/useIsPhoneSize";

export default (props) => {
  const isPhoneSize = useIsPhoneSize();
  const currentYear = moment().get("year");
  const divStyle = {
    backgroundImage: `url(${logo})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center", // Center the background image
    width: isPhoneSize ? "100%" : "100%",
    height: isPhoneSize ? "5rem" : "5rem",
    zIndex: "99999",
  };

  return (
    <div className="">
      <center>
        <p className="mb-0 text-center pt-5 mt-2">
          © {`${currentYear} `}
          <Card.Link
            href="https://kirmas.gov.ng"
            target="_blank"
            className="text-blue text-decoration-none fw-normal"
          >
            KIRMAS
          </Card.Link>
          {!isPhoneSize ? <Image style={divStyle} src={logo} /> : null}
        </p>
      </center>
    </div>
  );
};
