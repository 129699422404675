import React, { useCallback, useEffect, useState } from "react";
import CustomInput from "../examples/CustomInput";
import { CustomButton } from "../../components/UI";
import PaymentInvoice from "../PaymentInvoice";

import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../routes";
import { Alert, Card, Modal } from "@themesberg/react-bootstrap";
import { Button, Col, Row, Spinner } from "reactstrap";
import { _fetchApi, apiURL } from "../../redux/action/api";
import { useSelector } from "react-redux";
import ReceiptPreview from "../../components/ReceiptPreview";

function PayInvoice() {
    const { user } = useSelector(p => p.auth)
    const [refNo, setRefNo] = useState('')
    const history = useHistory();

    // const [showDefault, setShowDefault] = useState(false);
    // const toggle = () => setShowDefault(!showDefault);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')


    const displayReceipt = useCallback(() => {
        if (refNo) {
            _fetchApi(
                `/transactions/retrieve?query_type=paid_invoice&ref_no=${refNo}&user_id=${user?.id}`,
                (resp) => {
                    if (resp.success && resp.data && resp.data.length) {
                        setLoading(false);
                        history.push(
                            `${Routes.adminPaymentInvoice.path}?ref_no=${refNo}`
                        );
                    }
                },
                (err) => {
                    console.error(err);
                    setLoading(false);
                    setError("Operation failed");
                }
            );
        }
    }, [refNo]);


    return (
        <div>
            <div className="d-flex p-2  justify-content-center flex-wrap flex-md-nowrap align-items-center py-4 pt-8">
                <Card className="shadow col-md-6 py-5">
                    <Card.Body className="text-center p-4 ">
                        <div className="mb-4 mb-md-0">
                            <h4>Pay Invoice</h4>
                        </div>
                        {/* {JSON.stringify({ error })} */}

                        <div className="col-md-12">
                            <CustomInput
                                label="Enter Reference Number"
                                value={refNo}
                                className="text-center"
                                onChange={({ target: { value } }) => setRefNo(value)}
                            />
                        </div>
                        {error ? <Alert>
                            <p><strong className="text-danger">Notice</strong> {error}</p>
                        </Alert> : ''}
                        <CustomButton loading={loading} onClick={displayReceipt}>Pay</CustomButton>
                    </Card.Body>
                </Card>
            </div>

            {/* {ref_no && ref_no !== "" ?  <PaymentInvoice refNo={refNo} /> : null} */}
        </div>
    );
}

export default PayInvoice;
