import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Spinner, Table } from 'reactstrap';
import {
    _fetchApi,
    _postApi,
    calculateOnPercent,
    calculateTax,
    cgiFun,
    generateCRA,
    grossIncomeFun,
    nationalMinimumWage,
    taxableIncome,
} from '../../redux/action/api';
import { CustomButton, SearchBar } from '../../components/UI';
import CustomModal from '../../components/UI/CustomModal';
import CustomForm from '../../components/UI/CustomForm';
import CustomTable from '../../components/UI/CustomTable';
import { useHistory } from 'react-router-dom';
import { FaArrowCircleLeft, FaEyeSlash, FaPenAlt, FaRegEye, FaTrashAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Routes } from '../../routes';
import { Link } from '@material-ui/core';

const OrgStaff = () => {
    const { user } = useSelector((s) => s.auth);

    const _form = {
        staff_name: '',
        jtb_no: '',
        nin: '',
        staff_id: '',
        phone: '',
        basic_salary: '',
        other_allowance: '',
        nhf: '',
        pension: '',
        life_insurance: '',
        nhis: '',
        gross: '',
        org_kirmas_id: user.taxID,
        query_type: 'insert',
    };
    const [staff, setStaff] = useState([]);
    const [new_staff, setNewStaff] = useState([]);
    const [staff_name, setOrgName] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const [form, setForm] = useState(_form);
    const [loading, setLoading] = useState(false);
    const [loadingStaff, setLoadingStaff] = useState(false);
   
    const [staffs, setStaffs] = useState({});
    const handleChange = ({ target: { name, value } }) => {
        console.log(name, value)
        setStaffs((p) => ({ ...p, [name]: value }));
    };
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    };
    const getStaffList = useCallback(() => {
        setLoadingStaff(true);
        _fetchApi(
            `/assessments/org-staff-list?org_kirmas_id=${user.taxID}&max=50&query_type=select`,
            (resp) => {
                if (resp.success) {
                    setStaff(resp.data);
                }
                setLoadingStaff(false);
            },
            (err) => {
                console.log(err);
                setLoadingStaff(false);
                toast.error('Error Occured', err);
            },
        );
    }, [user.taxID]);
    useEffect(() => {
        getStaffList();
    }, [getStaffList]);
    const [loadingD, setLoadingD] = useState(false);
    const handleDiActivate = () => {
        setLoadingD(true);
        _fetchApi(
            `/users/di-activate-tax-payer?taxID=${selectedStaff?.taxID}`,
            (resp) => {
                if (resp.success) {
                    toast.success('Successfully De-Activated');
                    getStaffList();
                    toggle();
                }
                setLoadingD(false);
            },
            (err) => {
                console.log(err);
                setLoadingD(false);
                toast.error('Error Occured', err);
            },
        );
    };

    const handleSubmit = () => {
        setLoading(true);
        _postApi(
            '/assessments/update-staff-details',
            {
                taxID:staffs.taxID,
                basic_salary: staffs.basic_salary,
                other_allowances: staffs.other_allowances,
                nhf: staffs.nhf,
                nhis: staffs.nhis,
                pension: staffs.pension,
                gross: grossIncomeFun(staffs.basic_salary, staffs.other_allowances),
                cgi:
                    parseFloat(staffs.basic_salary) <= nationalMinimumWage
                        ? 0
                        : cgiFun(
                              grossIncomeFun(staffs.other_allowances, staffs.basic_salary),
                              staffs.pension,
                              staffs.nhf,
                              staffs.nhis,
                          ),
                cra:
                    parseFloat(staffs.basic_salary) <= nationalMinimumWage
                        ? 0
                        : generateCRA(
                              grossIncomeFun(staffs.other_allowances, staffs.basic_salary),
                              cgiFun(
                                  grossIncomeFun(staffs.other_allowances, staffs.basic_salary),
                                  staffs.pension,
                                  staffs.nhf,
                                  staffs.nhis,
                              ),
                          ),
                taxable_income:
                    parseFloat(staffs.basic_salary) <= nationalMinimumWage
                        ? 0
                        : taxableIncome(
                              staffs.pension,
                              staffs.nhf,
                              staffs.nhis,
                              staffs.other_allowances,
                              staffs.basic_salary,
                          ),
                payable:
                    parseFloat(staffs.basic_salary) <= nationalMinimumWage
                        ? 0
                        : taxableIncome(
                                staffs.pension,
                                staffs.nhf,
                                staffs.nhis,
                                staffs.other_allowances,
                                staffs.basic_salary,
                            ) === 0
                          ? calculateOnPercent(grossIncomeFun(staffs.other_allowances, staffs.basic_salary))
                          : calculateTax(
                                taxableIncome(
                                    staffs.pension,
                                    staffs.nhf,
                                    staffs.nhis,
                                    staffs.other_allowances,
                                    staffs.basic_salary,
                                ),
                            ),
            },
            (resp) => {
                if (resp.success) {
                    setLoading(false);
                    toast.success('Done');
                    setShowModal(false);
                    getStaffList();
                }
            },
            (err) => {
                console.log(err);
                setLoading(false);
                toast.error('Error Occured', err);
            },
        );
    };

    const _form_fields = [
        {
            name: 'basic_salary',
            label: 'Basic Salary(PER MONTH)',
            value: staffs.basic_salary,
            col: 6,
        },
        {
            name: 'other_allowances',
            label: 'Other Allowances(PER ANNUM)',
            value: staffs.other_allowances,
            col: 6,
        },
        {
            name: 'pension',
            label: 'Pension(PER ANNUM)',
            value: staffs.pension,
        },

        {
            name: 'nhf',
            label: 'NHF(PER ANNUM)',
            value: staffs.nhf,
        },
        {
            name: 'nhis',
            label: 'NHIS(PER ANNUM)',
            value: staffs.nhis,
        },
    ];
    const newstaffs = staff_name.length
        ? staff.filter((item) => {
              return item.name.toLowerCase().includes(staff_name.toLowerCase());
          })
        : staff;
    const containerStyle = {
        // height: "80vh",
        overflowY: 'scroll',
        scrollbarWidth: 'thin', // For Firefox
        // scrollbarColor: "transparent transparent", // For Firefox
        // overflow: "-moz-scrollbars-none",
        zIndex: 1,
    };
    const deleteNewStaff = (idx) => {
        setNewStaff((prevStaff) => prevStaff.filter((_, index) => index !== idx));
    };
    const deleteStaff = (idx) => {
        setStaff((prevStaff) => prevStaff.filter((_, index) => index !== idx));
    };
    const [selectedStaff, setSelectedStaff] = useState({});

    const renderTable = (data) => {
        return (
            <div style={containerStyle}>
                {/* {JSON.stringify(staffs)} */}
                <div>
                    <Table size="sm" bordered striped responsive>
                        <thead>
                            <tr>
                                <th className="text-">STAFF NAME</th>
                                <th className="text-center">KIRMAS ID</th>
                                <th className="text-center">PHONE</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((usr, idx) => (
                                <tr key={idx}>
                                    {/* {JSON.stringify(usr)} */}
                                    <td>{usr.name || usr.staff_name}</td>
                                    <td className="text-center">{usr.taxID}</td>
                                    <td className="text-center">{usr.phone}</td>
                                    <td className="text-center ">
                                        <Button
                                            size="sm"
                                            onClick={() =>
                                                history.push(
                                                    `${Routes.StaffLedgerUser.path}?org_id=${usr.parent_tax_id}&user_id=${usr.taxID}`,
                                                )
                                            }
                                            color="info"
                                            variant="info"
                                            title="View"
                                            className="m-1"
                                        >
                                            <FaRegEye />
                                        </Button>
                                        <Button
                                            className="m-1"
                                            size="sm"
                                            onClick={() => {
                                                setShowModal(true);
                                                setStaffs(usr);
                                            }}
                                            color="warning"
                                            variant="warning"
                                            title="Edit"
                                        >
                                            <FaPenAlt />
                                        </Button>
                                        <Button
                                            className="m-1"
                                            size="sm"
                                            onClick={() => {
                                                toggle();
                                                setSelectedStaff(usr);
                                            }}
                                            color="danger"
                                            variant="red"
                                            title="Delete"
                                        >
                                            <FaTrashAlt />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {!data.length ? (
                        <Alert className="text-center">
                            No staff uploaded yet.{' '}
                            <Link
                                onClick={() => {
                                    history.push(Routes.PayeSetup.path);
                                }}
                            >
                                Click here to upload the nominal roll
                            </Link>
                        </Alert>
                    ) : null}
                </div>
            </div>
        );
    };

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row>
                        <Col className="text-left  col-md-2 col-sm-3 col-lg-2">
                            <CustomButton size="sm" onClick={() => history.goBack()}>
                                <FaArrowCircleLeft />
                                Back
                            </CustomButton>
                        </Col>
                        <Col>
                            <h3 className="text-center">STAFF MANAGER</h3>
                        </Col>
                        <Col className="text-right col-md-4 col-sm-4 col-lg-4" md={3}>
                            <CustomButton size="sm" onClick={() => history.push(Routes.PayeSetup.path)}>
                                Upload/Add Staff
                            </CustomButton>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Col>
                        <SearchBar
                            onFilterTextChange={(text) => setOrgName(text)}
                            filterText={staff_name}
                            placeholder="Search user"
                        />
                    </Col>
                    {loadingStaff ? (
                        <center>
                            <Spinner size={'sm'} />
                        </center>
                    ) : null}
                    {renderTable(newstaffs, deleteStaff)}
                </CardBody>
                <CustomModal
                    size="lg"
                    toggle={() => {
                        setShowModal(!showModal);
                    }}
                    isOpen={showModal}
                >
                    {/* <Card>
                        <CardHeader>New Staff Form</CardHeader>
                        <CardBody> */}
                    <CustomForm fields={_form_fields} handleChange={handleChange} />
                    <Col className="text-center">
                        {/* <Button
                                    onClick={() => {
                                        setNewStaff((p) => [
                                            ...p,
                                            {
                                                ...form,
                                                org_kirmas_id: user.taxID,
                                                cgi: cgiFun(form.gross, form.pension, form.nhf, form.nhis),
                                                cra: generateCRA(
                                                    form.gross,
                                                    cgiFun(form.gross, form.pension, form.nhf, form.nhis),
                                                ),
                                                taxable_income: taxableIncome(
                                                    form.pension,
                                                    form.nhf,
                                                    form.nhis,
                                                    form.other_allowance,
                                                    form.basic_salary,
                                                ),
                                                payable: calculateTax(
                                                    taxableIncome(
                                                        form.gross * 0.08,
                                                        form.nhf,
                                                        form.nhis,
                                                        form.other_allowance,
                                                        form.basic_salary,
                                                    ),
                                                ),
                                            },
                                        ]);
                                        setForm(_form);
                                    }}
                                >
                                    Add to list
                                </Button> */}
                    </Col>
                    <hr />
                    {/* {renderTable(new_staff)} */}
                    {/* </CardBody> */}
                    {/* <CardFooter className="text-right">
                           
                        </CardFooter> */}
                    {/* </Card> */}
                    <center>
                        <CustomButton loading={loading} onClick={handleSubmit}>
                            Update
                        </CustomButton>
                    </center>
                </CustomModal>

                <CustomModal size="lg" toggle={toggle} isOpen={isOpen}>
                    <>
                        <div className="text-center">
                            <h4>Are you sure you want de-active {selectedStaff?.name}?</h4>
                        </div>
                    </>
                    <center>
                        <CustomButton size="sm" onClick={handleDiActivate} loading={loadingD}>
                            De-Active
                        </CustomButton>
                    </center>
                </CustomModal>
            </Card>
        </div>
    );
};

export default OrgStaff;
