import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import { _fetchApi, _postApi } from "../../redux/action/api";
import { useCallback } from "react";
import { Modal, ModalBody, Button, Table, Spinner } from "reactstrap";
import { SearchBar } from "../../components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCog } from "@fortawesome/free-solid-svg-icons";
import NewTaxFormModal from "./NewTaxFormModal";
import { toast } from "react-hot-toast";
import { formatNumber } from "../../utils";

export default function NewTaxFormTable({ type }) {
  const _form = {
    sector: type,
    parent_code: "",
    mda_code: "",
    title: "",
    tax_code: "",
    tax_fee: "",
    query_type: "create-tax",
    input_type: "",
    is_department: "0",
    is_head: '0',
    default: "",
    uom: "",
  };

  const [form, setForm] = useState(_form);
  const [taxHeads, setTaxHeads] = useState([]);
  // const [main_tax, setMainTax] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [modal, setModal] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [delConfirm, setDelConfirm] = useState(false);

  const confirmDel = () => {
    setDelConfirm(!delConfirm);
  };

  const toggle = () => setModal(!modal);

  const getTaxHeads = useCallback(() => {
    setLoadings(true);
    _fetchApi(
      `/kigra-taxes?query_type=select-sector-taxes&sector=${form.sector}`,
      (resp) => {
        if (resp.success && resp.result) {
          setLoadings(false);
          if (resp.result.length) {
            const tax_items = resp.result.filter(
              (tax) =>
                tax.title !== "NON TAX" &&
                tax.title !== "TAX" &&
                tax.title !== "VIHECLES" &&
                tax.title !== "LAND" &&
                tax.title !== "LGA"
            );

            setTaxHeads(tax_items);
          }

        }
      },
      () => {
        setTaxHeads([]);
        setLoadings(false);
      }
    );
  }, [form.sector]);

  const handleDeleteClick = () => {
    _postApi(
      "/kigra-taxes",
      [form],
      (res) => {
        console.log(res);
        toast.success("Tax deleted successfully");
        getTaxHeads();
        confirmDel();
      },
      (err) => {
        console.error(err);
        toast.error("Error Occured", err);
      }
    );
    console.log(form);
  };

  useEffect(() => {
    getTaxHeads();
  }, [getTaxHeads]);

  const FilteredTaxHeads =
    taxHeads?.length && filterText.length
      ? taxHeads.filter(
        (item) =>
          (item.title &&
            item.title.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.tax_parent_code &&
            item.tax_parent_code
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.tax_code &&
            item.tax_code.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.mda_name &&
            item.mda_name.toLowerCase().includes(filterText.toLowerCase()))
      )
      : taxHeads;

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <Col md={3}>
            <Button
              color="primary"
              style={{
                width: "100%",
                marginTop: "-5%",
              }}
              onClick={() => {
                toggle();
                setForm({ ..._form, edit: false });
              }}
            >
              Add New
            </Button>
          </Col>
          <h5 className="mb-4">{type || ""}</h5>
        </div>
        <Modal isOpen={modal} toggle={toggle} size="lg">
          <ModalBody>
            <NewTaxFormModal
              _form={form}
              type=""
              toggle={toggle}
              _getTaxHeads={getTaxHeads}
            />
          </ModalBody>
        </Modal>
        <Modal isOpen={delConfirm} toggle={confirmDel} size="md">
          <ModalBody>
            <h6 className="text-center">Confirm to delete</h6>
            <div className="text-danger display-4 text-center m-2">
              {form.title}
            </div>
            <center>
              <Button
                onClick={confirmDel}
                color="success"
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>
              <Button onClick={handleDeleteClick} color="danger">
                Confirm Delete
              </Button>
            </center>
          </ModalBody>
        </Modal>
        <Row>
          <Col sm={6} className="mb-3">
            <SearchBar
              onFilterTextChange={(val) => setFilterText(val)}
              filterText={filterText}
            />
          </Col>
        </Row>
        <Row
          style={{
            height: "65vh",
            overflowY: "scroll",
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            overflow: "-moz-scrollbars-none",
          }}
        >
          <Col md={12}>
            {loadings && (
              <div className="d-flex">
                <Spinner size={"sm"} />
                Loading....
              </div>
            )}
            <table className="border">
              <thead>
                <tr>
                  <th style={{ width: "20%", paddingLeft: 10 }}>Tax Head</th>
                  <th style={{ width: "20%" }}>Tax title</th>
                  <th style={{ width: "10%" }}>Revenue code</th>
                  <th style={{ width: "5%" }}>Tax Fee</th>
                  <th style={{ width: "5%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {FilteredTaxHeads.map((item, idx) => (
                  <tr key={idx}>
                    <td style={{ paddingLeft: 10 }}>{item.tax_parent_code}</td>
                    <td>{item.title}</td>
                    <td>{item.economic_code}</td>
                    <td className="text-right">{formatNumber(item.tax_fee)}</td>
                    <td>
                      <div
                        className="d-flex text-right"
                        style={{ marginLeft: 15 }}
                      >
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => {
                            setForm({ ...item, edit: true });
                            toggle();
                          }}
                          style={{ marginRight: 5 }}
                        >
                          <FontAwesomeIcon icon={faCog} />
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => {
                            confirmDel();
                            setForm({ ...item, query_type: "delete" });
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
