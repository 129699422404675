import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import DM_SANS_NORMAL from '../assets/DM_Sans/static/DMSans_24pt-SemiBold.ttf';
// import DM_SANS_NORMAL18 from "../assets/DM_Sans/static/DMSans_18pt-Black.ttf";
import DM_SANS_BOLD from '../assets/DM_Sans/static/DMSans_24pt-Bold.ttf';
import DM_SANS_ITALIC from '../assets/Protest_Revolution/Style_Script/StyleScript.ttf';
import Style_Script_Regular from '../assets/Protest_Revolution/Style_Script/StyleScript.ttf';
import moment from 'moment';
import { formatNumber } from '../utils';
import Signature from '../assets/img/mdsign.png';
import Butterfly from '../assets/img/butterfly.png';
import QRCode from 'qrcode';
Font.register({
    family: 'DM_SANS',
    fonts: [
        { src: DM_SANS_NORMAL, fontWeight: 700 },
        {
            src: DM_SANS_BOLD,
            fontStyle: 'bold',
        },
        {
            src: DM_SANS_ITALIC,
            fontStyle: 'italic',
        },
    ],
});
Font.register({
    family: 'Style_Script_Regular',
    fonts: [{ src: Style_Script_Regular, fontWeight: 700 }],
});

const styles = StyleSheet.create({
    body: {
        width: '100%',
        flexDirection: 'row',
        fontFamily: 'DM_SANS',
        pageBreakInside: 'avoid',
        padding: 20,
    },
    container: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingHorizontal: 0,
        borderStyle: 'solid',
        borderWidth: 22,
        borderColor: '#52a367',
    },

    image: {
        width: 80,
        height: 80,
        marginLeft: 18,
        marginTop: 10,
    },
    image1: {
        width: 80,
        height: 80,
        marginTop: 10,
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
    },
    headerText: {
        width: '100%',
        textAlign: 'center',
        fontSize: 16,
        // fontStyle: "bold",
        textTransform: 'uppercase',
        fontFamily: 'DM_SANS',
        // margin:2
    },
    secondHeaderText: {
        textTransform: 'uppercase',
        color: '#fff',
        backgroundColor: '#A54640',
        width: '50%',
        fontSize: 15,
        padding: 4,
        borderRadius: 12,
    },
    secondHeaderText1: {
        textTransform: 'capitalize',
        color: '#4B603F',
        width: '20%',
        fontSize: 10,
        padding: 4,
        borderRadius: 5,
        border: '1px solid #4B603F',
    },
    backgroundImage: {
        width: '20%',
        height: '30vh',
        margin: '25%',
        position: 'absolute', // Position the background image
        top: 0,
        left: 0,
        opacity: 0.3,
    },
    line: {
        width: '25%',
        borderBottom: '2px solid #52a367',
    },
    middle_line: {
        width: '80%',
        backgroundColor: '#52a367',
        padding: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: -14,
    },
    textter: {
        width: '100%',
        textAlign: 'center',
        fontSize: 20,
        textTransform: 'capitalize',
        // marginBottom: 10,
        fontFamily: 'Style_Script_Regular',
    },
    textter33: {
        width: '100%',
        textAlign: 'center',
        fontSize: 15,
        marginTop: 10,
        marginBottom: 10,
        fontFamily: 'Style_Script_Regular',
    },
    textter12: {
        width: '100%',
        textAlign: 'center',
        fontSize: 12,
        paddingTop: 12,
        fontFamily: 'DM_SANS',
        fontWeight: 'bold',
    },
    kirs1: {
        position: 'absolute',
        top: 115,
        left: 0,
        fontSize: 10,
        transform: 'rotate(90deg)',
        opacity: 0.2,
    },
    kirs2: {
        position: 'absolute',
        top: 60,
        right: 120,
        fontSize: 10,
        transform: 'rotate(90deg)',
        opacity: 0.2,
    },
    kirs3: {
        position: 'absolute',
        top: 115,
        right: 5,
        fontSize: 10,
        transform: 'rotate(90deg)',
        opacity: 0.2,
    },
    kirs4: {
        position: 'absolute',
        top: 170,
        left: 250,
        right: 250,
        fontSize: 10,
        transform: 'rotate(180deg)',
        opacity: 0.2,
    },
    kirs5: {
        position: 'absolute',
        top: 300,
        // left:250,
        right: -25,
        fontSize: 10,
        transform: 'rotate(90deg)',
        opacity: 0.2,
    },
    kirs6: {
        position: 'absolute',
        top: 300,
        left: -25,
        // right:250,
        fontSize: 10,
        transform: 'rotate(90deg)',
        opacity: 0.2,
    },

    kirs7: {
        position: 'absolute',
        bottom: 200,
        // left: 0,
        right: -25,
        fontSize: 10,
        transform: 'rotate(90deg)',
        opacity: 0.2,
    },

    kirs8: {
        position: 'absolute',
        bottom: 200,
        left: -25,
        // right:250,
        fontSize: 10,
        transform: 'rotate(90deg)',
        opacity: 0.2,
    },

    kirs9: {
        position: 'absolute',
        bottom: 50,
        left: 250,
        right: 250,
        fontSize: 10,
        opacity: 0.2,
    },

    kirs10: {
        position: 'absolute',
        top: 60,
        left: 120,
        fontSize: 10,
        transform: 'rotate(90deg)',
        opacity: 0.2,
    },
    butterfly: {
        position: 'absolute',
        top: 300,
        right: 50,
        fontSize: 6,
        transform: 'rotate(45deg)',
        opacity: 0.25,
    },
    butterfly1: {
        position: 'absolute',
        top: 425,
        right: 220,
        fontSize: 8,
        transform: 'rotate(45deg)',
        opacity: 0.25,
    },
    butterfly2: {
        position: 'absolute',
        top: 550,
        left: 70,
        fontSize: 10,
        transform: 'rotate(45deg)',
        opacity: 0.25,
    },
    // butterfly3: {
    //   position: "absolute",
    //   top: 600,
    //   left: 12,
    //   fontSize: 10,
    //   transform: "rotate(45deg)",
    //   opacity: 0.5,
    // },
    // kirs: {
    //   position: "absolute",
    //   top: 650,
    //   fontSize: 10,
    //   left: 0,
    //   transform: "rotate(45deg)",
    //   opacity: 0.4,
    // },
    textter2: {
        width: '100%',
        textAlign: 'center',
        fontSize: 9,
        textTransform: 'capitalize',
    },
    textter3: {
        width: '100%',
        textAlign: 'center',
        fontSize: 9,
        marginTop: 7,
    },
    textter1: {
        width: '100%',
        textAlign: 'center',
        fontSize: 14,
        textTransform: 'uppercase',
        // marginBottom: 10,
        fontStyle: 'bold',
        fontFamily: 'DM_SANS',
    },
    middle_line1: {
        width: '50.6%',
        backgroundColor: '#52a367',
        padding: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        marginLeft: '40%',
        flexDirection: 'row',
    },
    signatureImage: {
        width: '120px',
        height: '30px',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 5,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontStyle: 'bold',
        // fontFamily: "DM_SANS",
    },
});

export const TaxClearancePDF = ({ data = {} }) => {
    let canvas;
    // For QR Code
    canvas = document.createElement('canvas');
    QRCode.toCanvas(canvas, `https://verify.kirmas.kn.gov.ng/${data.tcc_ref}/tax/clearance`.replace(/\|/g, '%7C'));
    const qr = canvas.toDataURL();

    return (
        <Document>
            <Page size="A4" style={styles.container} wrap={false}>
                <View style={styles.header}>
                    <View style={{ width: '20%' }}>
                        <Image src={require('../assets/img/kigra.jpg')} style={styles.image} />
                    </View>
                    <View style={{ width: '60%' }}>
                        <View
                            style={{
                                flexDirection: 'column',
                                // width: "100%",
                                textAlign: 'center',
                            }}
                        >
                            <Text style={styles.headerText}>kano state government</Text>
                            <Text style={styles.headerText}>kano internal revenue service</Text>
                            <Text style={styles.headerText}>(KIRS)</Text>
                            <Text
                                style={{
                                    fontSize: 12,
                                    textTransform: 'capitalize',
                                    fontStyle: 'bold',
                                    padding: 2,
                                    fontFamily: 'DM_SANS',
                                }}
                            >
                                no. 2 bank road, kano
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    textTransform: 'capitalize',
                                    fontStyle: 'bold',
                                    padding: 2,
                                    fontFamily: 'DM_SANS',
                                }}
                            >
                                PMB No. 3052. KANO
                            </Text>
                            <Text
                                style={{
                                    fontSize: 8,
                                    fontStyle: 'bold',
                                    paddingTop: 2,
                                    fontFamily: 'DM_SANS',
                                }}
                            >
                                www.kirs.gov.ng email:tax@kirs.gov.ng,info@kirs.gov.ng
                            </Text>
                        </View>
                    </View>
                    <View style={{ width: '20%' }}>
                        <Image src={require('../assets/img/download.jpeg')} style={styles.image1} />
                    </View>
                </View>
                <View style={{ flexDirection: 'row', width: '100%', marginTop: 0 }}>
                    <View style={styles.line}></View>
                    <View style={styles.middle_line}>
                        <Text
                            style={{
                                fontSize: 14,
                                color: '#fff',
                                fontStyle: 'bold',
                                fontFamily: 'DM_SANS',
                            }}
                        >
                            INCOME TAX CLEARANCE CERTIFICATE
                        </Text>
                    </View>
                    <View style={styles.line}>
                        <Text
                            style={{
                                fontSize: 9,
                                fontStyle: 'bold',
                                paddingLeft: 30,
                                paddingTop: 5,
                                fontFamily: 'DM_SANS',
                            }}
                        >
                            REV 15A
                        </Text>
                    </View>
                </View>

                <View
                    style={{
                        width: '100%',
                        flexDirection: 'row',
                        marginLeft: 30,
                        marginTop: 8,
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'column',
                            width: '50%',
                            textAlign: 'center',
                            fontSize: 9,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 8,
                                color: '#000',
                                fontStyle: 'bold',
                                width: '100%',

                                textAlign: 'justify',
                            }}
                        >
                            TIN
                        </Text>
                        <Text
                            style={{
                                fontSize: 9,
                                color: '#000',
                                fontStyle: 'bold',
                                width: '100%',
                                textAlign: 'justify',
                                fontFamily: 'DM_SANS',
                            }}
                        >
                            {data.tin || 'N/A'}
                        </Text>
                        <Text
                            style={{
                                fontSize: 8,
                                color: '#000',
                                textTransform: 'uppercase',
                                fontStyle: 'bold',
                                width: '100%',
                                textAlign: 'justify',
                            }}
                        >
                            Tax File No.
                        </Text>
                        <Text
                            style={{
                                fontSize: 9,
                                color: '#000',
                                textTransform: 'uppercase',

                                fontStyle: 'bold',
                                width: '100%',
                                textAlign: 'justify',
                                fontFamily: 'DM_SANS',
                            }}
                        >
                            {data.tax_file_no || 'N/A'}
                        </Text>
                        <Text
                            style={{
                                fontSize: 8,
                                color: '#000',
                                textTransform: 'uppercase',

                                fontStyle: 'bold',
                                width: '100%',
                                textAlign: 'justify',
                            }}
                        >
                            Certificate No.
                        </Text>
                        <Text
                            style={{
                                fontSize: 9,
                                color: '#000',
                                textTransform: 'uppercase',
                                fontStyle: 'bold',
                                width: '100%',
                                textAlign: 'justify',
                                fontFamily: 'DM_SANS',
                            }}
                        >
                            {data.tcc_ref || 'N/A'}
                        </Text>
                    </View>
                    <View
                        style={{
                            flexDirection: 'column',
                            width: '50%',
                            textAlign: 'center',
                            fontSize: 10,
                            marginLeft: '55%',
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 10,
                                color: '#000',
                                fontStyle: 'bold',
                                width: '100%',
                                textAlign: 'justify',
                            }}
                        >
                            Date:
                        </Text>
                        <Text
                            style={{
                                fontSize: 10,
                                color: '#000',
                                fontStyle: 'bold',
                                width: '100%',
                                textAlign: 'justify',
                                fontFamily: 'DM_SANS',
                            }}
                        >
                            {moment(data.date_issued).format('D-MMMM, YYYY')}
                        </Text>
                    </View>
                </View>
                <View>
                    <View
                        style={{
                            flexDirection: 'column',
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <Text style={styles.textter}>this is to certify that:</Text>
                        <Text style={styles.textter1}>{data.tax_payer || 'N/A'}</Text>
                        <Text style={styles.textter}>of</Text>
                        <Text style={styles.textter2}>
                            {data.type === 'Formal Businesses' ? data.org_name : data.address || 'N/A'}
                        </Text>
                        <Text style={styles.textter3}>
                            has settled his/her income tax assessment for the past three year i.e
                        </Text>
                        <Text style={styles.textter12}>
                            {moment(data.year).subtract(2, 'year').format('YYYY')} |{' '}
                            {moment(data.year).subtract(1, 'year').format('YYYY')} | {moment(data.year).format('YYYY')}
                        </Text>
                        <Text style={styles.textter33}>Assessments Details as follows</Text>
                    </View>
                    <View style={styles.middle_line1}>
                        <Text
                            style={{
                                fontSize: 12,
                                color: '#fff',
                                fontStyle: 'bold',
                                width: '40%',
                                fontFamily: 'DM_SANS',
                                marginLeft: 8,
                                textAlign: 'left',
                            }}
                        >
                            {moment(data.year).subtract(2, 'year').format('YYYY')}
                        </Text>
                        <Text
                            style={{
                                fontSize: 12,
                                color: '#fff',
                                fontStyle: 'bold',
                                width: '40%',
                                textAlign: 'left',
                                marginLeft: 8,
                            }}
                        >
                            {moment(data.year).subtract(1, 'year').format('YYYY')}
                        </Text>
                        <Text
                            style={{
                                fontSize: 12,
                                color: '#fff',
                                fontStyle: 'bold',
                                width: '40%',
                                textAlign: 'left',
                                marginLeft: 8,
                            }}
                        >
                            {moment(data.year).format('YYYY')}
                        </Text>
                    </View>
                    <View
                        style={{
                            border: '1px solid #52a367',
                            width: '80%',
                            marginLeft: 60,
                            marginRight: 320,
                            padding: 10,
                        }}
                    >
                        <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: '#000',
                                    fontStyle: 'bold',
                                    width: '40%',
                                    fontFamily: 'DM_SANS',
                                }}
                            >
                                Total Income
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: '#000',
                                    fontStyle: 'bold',
                                    width: '20%',
                                    textAlign: '',
                                }}
                            >
                                {formatNumber(data.first_income)}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: '#000',
                                    fontStyle: 'bold',
                                    width: '20%',
                                    textAlign: '',
                                }}
                            >
                                {formatNumber(data.second_income)}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: '#000',
                                    fontStyle: 'bold',
                                    width: '20%',
                                    textAlign: '',
                                }}
                            >
                                {formatNumber(data.third_income)}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: '#000',
                                    fontStyle: 'bold',
                                    width: '40%',
                                    fontFamily: 'DM_SANS',
                                }}
                            >
                                Tax Paid
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: '#000',
                                    fontStyle: 'bold',
                                    width: '20%',
                                    textAlign: '',
                                }}
                            >
                                {formatNumber(data.first_amount)}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: '#000',
                                    fontStyle: 'bold',
                                    width: '20%',
                                    textAlign: '',
                                }}
                            >
                                {formatNumber(data.second_amount)}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: '#000',
                                    fontStyle: 'bold',
                                    width: '20%',
                                    textAlign: '',
                                }}
                            >
                                {formatNumber(data.third_amount)}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: '#000',
                                    fontStyle: 'bold',
                                    width: '40%',
                                    fontFamily: 'DM_SANS',
                                }}
                            >
                                Date Tax Reciept Issued
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: '#000',
                                    fontStyle: 'bold',
                                    width: '20%',
                                    textAlign: '',
                                }}
                            >
                                {moment(data.first_year).format('DD/MM/YYYY')}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: '#000',
                                    fontStyle: 'bold',
                                    width: '20%',
                                    textAlign: '',
                                }}
                            >
                                {moment(data.second_year).format('DD/MM/YYYY')}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: '#000',
                                    fontStyle: 'bold',
                                    width: '20%',
                                    textAlign: '',
                                }}
                            >
                                {moment(data.third_year).format('DD/MM/YYYY')}
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{
                            flexDirection: 'column',
                            width: '100%',
                            textAlign: 'center',
                            fontSize: 10,
                        }}
                    >
                        {data.type === 'KRIT' ? (
                            <View>
                                <View style={{ marginTop: 15 }}>
                                    <Text style={styles.textter2}>Certificate Valid For</Text>
                                </View>
                                <Text
                                    style={{
                                        fontSize: 9,
                                        color: '#000',
                                        fontStyle: 'bold',
                                        width: '100%',
                                        marginTop: 7,
                                        marginBottom: 20,
                                        textAlign: 'center',
                                        fontFamily: 'DM_SANS',
                                    }}
                                >
                                    {data.income_source || 'N/A'}
                                </Text>
                            </View>
                        ) : (
                            <View>
                                <View style={{ marginTop: 15 }}>
                                    <Text style={styles.textter2}>Source(s) of Income</Text>
                                </View>
                                <Text
                                    style={{
                                        fontSize: 9,
                                        color: '#000',
                                        fontStyle: 'bold',
                                        width: '100%',
                                        marginTop: 7,
                                        marginBottom: 20,
                                        textAlign: 'center',
                                        fontFamily: 'DM_SANS',
                                    }}
                                >
                                    {data.income_source || 'N/A'}
                                </Text>
                            </View>
                        )}
                        <Text style={styles.textter2}>This Certificate expires on</Text>
                        <Text
                            style={{
                                fontSize: 9,
                                color: '#000',
                                fontStyle: 'bold',
                                width: '100%',
                                marginTop: 7,
                                textAlign: 'center',
                                fontFamily: 'DM_SANS',
                            }}
                        >
                            {data.type == 'KRIT'
                                ? moment(data.date_issued).add(3, 'months').format('D-MMMM, YYYY')
                                : `31-December ${moment(data.date_issued).format('YYYY')}`}
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        margin: 50,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <View style={{ marginTop: 20, border: '1px solid #52a367', padding: 0 }}>
                        <Image
                            source={qr}
                            style={{
                                width: 80,
                                height: 80,
                            }}
                        />
                    </View>
                    <View style={{ alignItems: 'center', marginTop: 10 }}>
                        <View style={{ marginBottom: 5 }}>
                            <Image src={Signature} style={styles.signatureImage} />
                        </View>
                        <Text
                            style={{
                                fontSize: 10,
                                fontFamily: 'DM_SANS',
                                marginBottom: 1,
                                textAlign: 'center',
                            }}
                        >
                            DR. ZAID ABUBAKAR
                        </Text>
                        <Text
                            style={{
                                fontSize: 9,
                                fontFamily: 'DM_SANS',
                                marginBottom: 5,
                                textAlign: 'center',
                            }}
                        >
                            EXECUTIVE CHAIRMAN
                        </Text>
                        <Text
                            style={{
                                fontSize: 8,
                                marginBottom: 5,
                                textAlign: 'center',
                                textTransform: 'uppercase',
                            }}
                        >
                            kano internal revenue service
                        </Text>
                        <Text style={{ fontSize: 8, textAlign: 'center' }}>(KIRS)</Text>
                    </View>
                </View>
                <Text style={styles.pageNumber} fixed>
                    Please Visit:
                    <Text style={{ fontFamily: 'DM_SANS', fontWeight: 'bold' }}>
                        {''}https://verify.kirmas.kn.gov.ng/{data.tcc_ref}/tax/clearance
                    </Text>{' '}
                    For <Text style={{ fontFamily: 'DM_SANS', fontWeight: 'bold' }}>Authentication</Text> Purposes.
                </Text>
                <Text style={styles.kirs1}>KIRS</Text>
                {/* <Text style={styles.kirs}>KIRS</Text> */}
                <Text style={styles.kirs2}>KIRS</Text>
                <Text style={styles.kirs3}>KIRS</Text>
                <Text style={styles.kirs10}>KIRS</Text>
                <Text style={styles.kirs4}>{data.tcc_ref}</Text>
                <Text style={styles.kirs5}>{data.tcc_ref}</Text>
                <Text style={styles.kirs6}>{data.tcc_ref}</Text>
                <Text style={styles.kirs7}>{data.tcc_ref}</Text>
                <Text style={styles.kirs8}>{data.tcc_ref}</Text>
                <Text style={styles.kirs9}>{data.tcc_ref}</Text>

                <View style={styles.butterfly}>
                    <Image
                        source={Butterfly}
                        style={{
                            width: 100,
                            height: 100,
                        }}
                    />
                </View>
                <View style={styles.butterfly1}>
                    <Image
                        source={Butterfly}
                        style={{
                            width: 100,
                            height: 100,
                        }}
                    />
                </View>
                <View style={styles.butterfly2}>
                    <Image
                        source={Butterfly}
                        style={{
                            width: 100,
                            height: 100,
                        }}
                    />
                </View>
                {/* <View style={styles.butterfly2}>
          <Image
            source={Butterfly}
            style={{
              width: 100,
              height: 100,
            }}
          />
        </View> */}
            </Page>
        </Document>
    );
};
