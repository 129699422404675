import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardText, Row, Col, CardTitle, CardHeader, Button, Badge } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faPaste, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import moment from "moment";
import { _fetchApi } from "../../redux/action/api";
import toast from "react-hot-toast";
import { toCurrency } from "../../utils";
import { Modal } from "@themesberg/react-bootstrap";
import PayAssessment from "../generate-receipts/PayAssessment";
import { SearchBar } from "../../components/UI";

const Withholding = () => {
	const today = moment()
	const { user, tax_account } = useSelector(s => s.auth)
	const [trx, setTrx] = useState([])
	const yearStart = moment().startOf("month").format("YYYY-MM-DD");
	const yearEnd = moment().endOf("month").format("YYYY-MM-DD");

	const [refNo, setRefNo] = useState(null);
	const [showPDF, setShowPDF] = useState(false)
	const [form, setForm] = useState({
		from: yearStart,
		to: yearEnd
	})
	const togglePDF = (x) => {
		if (x) {
			setShowPDF(!showPDF)
			setRefNo(x)
		} else {
			setShowPDF(!showPDF)
		}
	}
	const getTrx = useCallback(() => {
		if (tax_account.taxID) {
			_fetchApi(
				`/transactions/retrieve?query_type=view_payer_wht&user_id=${tax_account.taxID}&start_date=${form.from}&end_date=${form.to}`,
				(resp) => {
					setTrx(resp.data);
				},
				(err) => {
					setTrx([]);
					console.log(err);
					toast.error("Failed to retrieve transactions");
				}
			);
		}
	}, [form.from, form.to, tax_account.taxID]);

	useEffect(() => {
		getTrx();
	}, [getTrx]);
	const invoiceItems = trx.length ? trx.filter(x => x.status === 'saved') : []
	const paidItems = trx.length ? trx.filter(x => x.status !== 'saved') : []
	const creditNote = invoiceItems.reduce((it, id) => it + parseFloat(id.dr), 0)
	const balance = paidItems.reduce((it, id) => it + parseFloat(id.dr), 0) - creditNote;
	return (
		<>{showPDF ? <Card>
			<Modal size="xl" fullscreen as={Modal.Dialog} centered show={showPDF}>
				<Modal.Header>
					<Modal.Title className="h6">Invoice No.: {refNo}</Modal.Title>
					<Button variant="danger" className="bg-danger text-white" aria-label="Close" onClick={() => setShowPDF(false)}>
						X
					</Button>
				</Modal.Header>
				<Modal.Body>{refNo ? <PayAssessment refNo={refNo} /> : null}</Modal.Body>
			</Modal>
		</Card> :
			<Row className="p-3">

				<Col md={4}>
					<Card>
						<CardBody style={{
							display: "flex",
							alignItems: "center"
						}}>
							<div className="me-3 p-2 rounded-circle border border-4 border-danger">
								<FontAwesomeIcon icon={faUserCircle} size="3x" className="text-danger" />
							</div>

							<div style={{
								alignItems: 'center'
							}}>
								<CardTitle tag="h6">Account Balance</CardTitle>
								<CardText tag="h4">₦ {toCurrency(balance)}</CardText>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<CardBody style={{
							display: "flex",
							alignItems: "center"
						}}>
							<div className="me-3 p-2 rounded-circle border border-4 border-warning">
								<FontAwesomeIcon icon={faAward} style={{ width: 46, height: 46 }} className="text-warning" />
							</div>

							<div style={{
							}}>
								<CardTitle tag="h6">Credit Note</CardTitle>
								<CardText tag="h4">₦ {toCurrency(creditNote)}</CardText>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<CardBody style={{
							display: "flex",
							alignItems: "center"
						}}>
							<div className="me-3 p-2 rounded-circle border border-4 border-secondary">
								<FontAwesomeIcon icon={faPaste} size="3x" style={{ width: 46, height: 46 }} className="text-secondary" />
							</div>
							<div style={{
								alignItems: 'center',
							}}>
								<CardTitle tag="h6">Receipt</CardTitle>
								<CardText tag="h4">{paidItems.length}</CardText>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col md={12} lg={12} className="py-lg-4 py-md-3 py-xs-3">
					<Card>
						<CardHeader className="d-flex justify-content-between bg-light">
							<b>
								Credit Note
							</b>
							<span class="badge bg-warning">{trx.length}</span>
						</CardHeader>
						<CardBody>
							<SearchBar />
							<div class="table-responsive">
								<table class="table table-bordered">
									<thead>
										<tr>
											<td>Date</td>
											<td>Tax Payer</td>
											<td>Invoice No</td>
											<td>Description</td>
											<td>WHT Amount</td>
											<td>Period</td>
											<td>Operations</td>
										</tr>
									</thead>
									<tbody>
										{trx.map((item, idx) => {
											if (item.status === 'saved') {
												return <tr key={idx}>
													<td>{moment(item.transaction_date).format('DD/MM/YYYY')}</td>
													<td className="text-center">{item.tax_payer}</td>
													<td className="text-center">{item.reference_number}</td>
													<td>{item.description}</td>
													<td className="text-right">{toCurrency(item.dr)}</td>
													<td>{moment(item.date_from).format('DD/MM/YYYY')}-{moment(item.date_to).format('DD/MM/YYYY')}</td>
													<td><Button color="warning" onClick={() => { togglePDF(item.reference_number) }}>Pay</Button></td>
												</tr>
											} else {
												return <tr className="bg-info text-white p-0" key={idx}>
													<td>{moment(item.paymentdate).format('DD/MM/YYYY')}</td>
													<td className="text-center">{item.tax_payer}</td>
													<td className="text-center">{item.reference_number}</td>
													<td>{item.description}</td>
													<td className="text-right">{toCurrency(item.dr)}</td>
													<td>{moment(item.date_from).format('DD/MM/YYYY')}-{moment(item.date_to).format('DD/MM/YYYY')}</td>
													{/* <td><Badge color="blue">{item.status}</Badge></td> */}
													<td><Button>View</Button></td>
												</tr>
											}
										})}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
				</Col>
				{/* <Col md={12} lg={12} className="pt-md-2">
					<Card>
						<CardHeader className="d-flex justify-content-between bg-light">
							<b>
								Receipt
							</b>
							<span class="badge bg-secondary">{paidItems.length}</span>
						</CardHeader>
						<CardBody>
							<div class="table-responsive">
								<table class="table table-bordered">
									<thead>
										<tr>
											<td>Date Paid</td>
											<td>Ref. No.</td>
											<td>Description</td>
											<td>WHT Amount</td>
											<td>Period</td>
											<td>Status</td>
											<td>Operations</td>
										</tr>
									</thead>
									<tbody>
										{paidItems.map((item, idx) => <tr key={idx}>
											<td>{moment(item.paymentdate).format('DD/MM/YYYY')}</td>
											<td className="text-center">{item.reference_number}</td>
											<td>{item.description}</td>
											<td className="text-right">{toCurrency(item.dr)}</td>
											<td>{moment(item.date_from).format('DD/MM/YYYY')}-{moment(item.date_to).format('DD/MM/YYYY')}</td>
											<td><Badge color="blue">{item.status}</Badge></td>
											<td><Button>View</Button></td>
										</tr>)}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
				</Col> */}
			</Row>
		}
		</>
	)
}
export default Withholding;