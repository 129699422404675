import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../routes";
import {
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    CardText,
    Table,
    Col,
    Row,
    Dropdown,
    Badge,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAward,
    faPaste,
    faUserCircle,
    faEye,
    faCheck,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import moment from "moment";
import { _fetchApi } from "../redux/action/api";
import toast from "react-hot-toast";
import { toCurrency } from "../utils";
import { Button, Modal } from '@themesberg/react-bootstrap';


const UserDashboard = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const history = useHistory();
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const { user } = useSelector(s => s.auth);
    const [payeList, setPayeList] = useState([]);
    const [assessments, setAssessments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(true)

    const [form, setForm] = useState({
        from: `${moment().format('YYYY')}-01-01`,
        to: `${moment().format('YYYY')}-12-30`
    });

    const handleView = (invoice) => {
        console.log('Invoice:', invoice);
        history.push(Routes.ViewInvoice.path, { invoice });
    };

    const handleVerify = (invoice) => {
        console.log(`Verifying invoice ${invoice.id}`);
    };

    const handleDelete = (invoice) => {
        console.log(`Deleting invoice ${invoice.id}`);
    };

    useEffect(() => {
        if (user?.taxID) {
            _fetchApi(
                `/transactions/retrieve?query_type=view_payer_ledger&user_id=${user?.taxID}&start_date=${form.from}&end_date=${form.to}`,
                (resp) => {
                    if (resp.success && resp?.data?.length) {
                        setAssessments(resp.data);
                    } else setAssessments([]);
                },
                (err) => {
                    console.error(err);
                    setAssessments([]);
                    toast.error("Failed to retrieve transactions");
                }
            );
        }
    }, [user?.taxID, form]);

    useEffect(() => {
        setLoading(true);
        _fetchApi(
            `/assessments/paye-list?query_type=select-ledger&taxID=${user?.taxID}&org_id=${''}&start_date=${form.from}&end_date=${form.to}`,
            (resp) => {
                if (resp.success && resp?.data?.length) {
                    setPayeList(resp.data);
                } else {
                    // setDataStatus('Record not found')
                    setPayeList([]);
                }
            },
            (err) => {
                console.error(err);
                // setDataStatus('Record not found')
                toast.error("Record not found")
                setPayeList([]);
            })
    }, [user, form]);

    const total_tax = payeList.filter(x => x.description === 'Pay As You earn').reduce((it, id) => it + parseFloat(id.amount_paid), 0)

    return (
        <Row className="p-3">
            {/* {JSON.stringify(user)} */}
            <Col md={4}>
                <Card>
                    <CardBody style={{ display: "flex", alignItems: "center" }} className="text-info">
                        <FontAwesomeIcon icon={faUserCircle} size="3x" className="me-4" />
                        <div style={{ alignItems: "center" }}>
                            <CardTitle tag="h6">KIRMAS ID</CardTitle>
                            <CardText tag="h3">{user.taxID}</CardText>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col md={4}>
                <Card>
                    <CardBody style={{ display: "flex" }} className="text-warning">
                        <FontAwesomeIcon icon={faAward} size="3x" className="me-4" />
                        <div>
                            <CardTitle tag="h6">My TCC</CardTitle>
                            <CardText tag="h3">0</CardText>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col md={4}>
                <Card>
                    <CardBody style={{ display: "flex" }} className="text-secondary">
                        <FontAwesomeIcon icon={faPaste} size="3x" className="me-4" />
                        <div style={{ alignItems: "center" }}>
                            <CardTitle tag="h6">TAX</CardTitle>
                            <CardText tag="h3">₦{toCurrency(total_tax)}</CardText>
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Col md={12} lg={12} className="py-lg-4 py-md-3 py-xs-3">
                <Card>
                    {/* {JSON.stringify(payeList)} */}
                    <CardHeader>
                        <CardTitle className="d-flex justify-content-between bg-success" tag="h5">
                            My Paye
                            <Badge>2</Badge>
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Description</th>
                                    {/* <th>Period</th> */}
                                    {/* <th>Ref. No.</th> */}
                                    <th>Amount</th>
                                    <th>Status</th>
                                    {/* <th>Operation</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {payeList.filter(x => x.description === 'Pay As You earn').map((invoice, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{moment(invoice.dr).format('DD/MM/YYYY')}</td>
                                        <td>{invoice.description}</td>
                                        {/* <td>{invoice.date_from}-{invoice.date_to}</td> */}
                                        {/* <td>{invoice.reference_number}</td> */}
                                        <td className="text-right">{toCurrency(invoice.amount_paid)}</td>
                                        <td>
                                            <Badge>{invoice.status}</Badge>
                                        </td>
                                        {/* <td>
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                size="1x"
                                                className="me-2"
                                                onClick={() => handleView(invoice)}
                                            />
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                size="1x"
                                                className="me-2"
                                                onClick={() => handleVerify(invoice)}
                                            />
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                size="1x"
                                                onClick={() => handleDelete(invoice)}
                                            />
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
            <Col md={12} lg={12} className="pt-md-2">


                <Card>
                    <CardHeader>
                        <CardTitle className="d-flex justify-content-between bg-success" tag="h5">
                            Other Assessments
                            <Badge>{ }</Badge>
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Period</th>
                                    <th>Ref. No.</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    {/* <th>Operation</th> */}
                                </tr>
                            </thead>
                            <tbody>

                                {assessments.map((invoice, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{moment(moment.dr).format('DD/MM/YYYY')}</td>
                                        <td>{invoice.description}</td>
                                        <td>{invoice.date_from}-{invoice.date_to}</td>
                                        <td>{invoice.reference_number}</td>
                                        <td className="text-right">{invoice.dr}</td>
                                        <td>
                                            <Badge>{invoice.status}</Badge>
                                        </td>
                                        {/* <td>
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                size="1x"
                                                className="me-2"
                                                onClick={() => handleView(invoice)}
                                            />
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                size="1x"
                                                className="me-2"
                                                onClick={() => handleVerify(invoice)}
                                            />
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                size="1x"
                                                onClick={() => handleDelete(invoice)}
                                            />
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                    {/* <Modal size="xl" fullscreen as={Modal.Dialog} centered show={show}>
                        <Modal.Header>
                            <Modal.Title className="h6">NOTICE</Modal.Title>
                            <Button variant="danger" className="bg-danger text-white" aria-label="Close" onClick={() => setShow(false)}>
                                X
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <h3>We are uprading the User Interface of our system</h3>
                            <h4>If you want to use the old Invoicing click <a onClick={history.push(Routes.Selection.path)} href="#">Here</a></h4>
                        </Modal.Body>
                    </Modal> */}
                </Card>
            </Col>
        </Row>
    );
};

export default UserDashboard;
