import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  Table,
  Spinner
} from "reactstrap";
import { _fetchApi } from "../redux/action/api";
import { SearchBar } from "./UI";
import { FaRegEye } from "react-icons/fa";


function ViewConsultant() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchType, setSearchType] = useState("ID");

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      '/getAllTaxconsultant',
      (res) => {
        setLoading(false);
        setData(res.data || []);
        setFilteredData(res.data || []);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, []);

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchType = (e) => {
    setSearchType(e.target.value);
  };

  const handleSearch = () => {
    if (searchInput.trim() === "") return;
    setLoading(true);

    const endpoint = searchType === "ID"
      ? `/getTaxconsultant/${searchInput}`
      : `/getAllTaxconsultant`;

    const queryType = searchType === "ID" ? "select" : "select-by-name";

    _fetchApi(
      endpoint,
      {
        query_type: "select-by-name",
        email: searchInput,
      },
      (res) => {
        setLoading(false);
        if (searchType === "ID") {
          setFilteredData(res.data ? [res.data] : []);
        } else {
          const filtered = res.data.filter(consultant =>
            consultant.name.toLowerCase().includes(searchInput.toLowerCase())
          );
          setFilteredData(filtered);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  };


  return (
    <div>
      <Card>
        <CardHeader className="bg-light">
          <h4>Search For Consultant</h4>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col>
                <Label>Search</Label>
                <SearchBar
                  filterText={searchInput}
                  onChange={(e) => setSearchInput(e)}
                />
              </Col>
              <Col md={2} style={{ marginTop: "29px" }}>
                <Button onClick={handleSearch}>Search</Button>
              </Col>
            </Row>
          </Form>
          <Table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>KIRMAS ID</th>
                <th>Consultant Name</th>
                <th>Rating Scores</th>
                <th>Operations</th>
              </tr>
            </thead>

            {loading ?
              (
                <tbody>
                  <tr>
                    <td colSpan={4} className="text-center">
                      <Spinner
                        color="light"
                        className="spinner"
                        type="grow"
                        style={{ margin: "20px auto" }}
                      >
                        ""
                      </Spinner>
                    </td>
                  </tr>
                </tbody>
              ) : data.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan={4} className="text-center">No data found</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {data?.map((item, idx) => (
                    (
                      <tr key={idx}>
                        <td scope="row">{idx + 1}</td>
                        <td>{item.taxID}</td>
                        <td>{item.name}</td>
                        <td>{item.name}</td>
                        <td className="text-center"><Button size='sm' color='info' variant='info' className=""><FaRegEye /></Button></td>
                      </tr>
                    ))
                  )}
                </tbody>
              )
            }


          </Table>
        </CardBody>
      </Card>
    </div >
  );
}

export default ViewConsultant;